<template>
  <div class="index">
    <div class="left">
      <!-- 谷歌地图上的控件 -->
      <div id="logContainer" class="log search" v-show="zoomToggle" style="margin: 20px">
        <el-row style="box-shadow: 0px 12px 16px 1px rgba(185,188,206,0.4);border-radius: 0px 0px 0px 0px;"
          v-show="searchShow == 1">
          <el-col :span="20">
            <el-input placeholder="搜索" v-model="searchText" clearable>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" icon="el-icon-search" @click="getScooterdetailInfo(searchText)"></el-button>
          </el-col>
        </el-row>
        <el-row style="box-shadow: 0px 12px 16px 1px rgba(185,188,206,0.4);border-radius: 0px 0px 0px 0px;"
          v-show="searchShow == 0">
          <el-col :span="20">
            <el-input placeholder="搜索" v-model="searchText" @input="toSearch()" @clear="clearSearch()" clearable>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" icon="el-icon-search" @click="getPointInfo(searchText)"></el-button>
          </el-col>
        </el-row>
        <div class="search-list" v-show="searchShow == 0 && pointSearchList.length > 0">
          <p v-for="item in pointSearchList" :key="item.pointId" @click="getPointInfo(item.pointId)">{{ item.pointName }}
          </p>
        </div>
        <div v-if="info" style="background: white;margin-top: 27px;">
          <div class="info-personal">
            <img src="" alt="" />
            <div>
              <span>{{ info.licensePlate }}</span>
              <span>({{ info.scooterCode }})</span>
              <span v-if="info.connectStatus == 0" class="zaixian">在线</span>
              <span v-else class="lixian">离线</span>
            </div>
          </div>
          <div class="info-motorcycle">
            <div>
              <img src="./../../assets/img/time.png" alt="" />
              更新时间
            </div>
            <span>{{ info.gpsTime }}</span>
          </div>
          <div class="info-motorcycle">
            <div>
              <img src="./../../assets/img/company.png" alt="" />
              客户
            </div>
            <span>{{ info.clientName }}</span>
          </div>
          <div class="info-motorcycle">
            <div>
              <img src="./../../assets/img/people.png" alt="" />
              用户
            </div>
            <span>{{ info.username }}</span>
          </div>
          <div class="info-motorcycle">
            <div>
              <img src="./../../assets/img/money.png" alt="" />
              剩余里程
            </div>
            <span v-if="info.distanceBalance">{{ info.distanceBalance }}Km</span>
          </div>
          <div class="info-motorcycle">
            <div>
              <img src="./../../assets/img/km.png" alt="" />
              仪表总里程
            </div>
            <span v-if="info.mileageValues">{{ info.mileageValues }}Km</span>
          </div>
          <div class="info-motorcycle">
            <div>
              <img src="./../../assets/img/hours.png" alt="" />
              用车时长
            </div>
            <span v-if="info.initDays">{{ info.initDays }}days</span>
          </div>
          <div class="info-motorcycle" style="border-bottom: 0px">
            <div>
              <img src="./../../assets/img/address.png" alt="" />
              经纬度
            </div>
            <span>{{ [info.longitude, info.latitude] }}</span>
          </div>
        </div>
        <div style="background: white;margin-top: 27px;padding: 15px;" v-if="pointinfo && pointSearchList.length == 0">
          <p style="font-size: 16px;">{{ pointinfo.pointName }}</p>
          <div style="margin: 5px 0 10px">
            <span class="zaixian" style="font-size: 12px;" v-if="pointinfo.pointStatus == 'open'">营业中</span>
            <span class="lixian" style="font-size: 12px;" v-else>已打样</span>
          </div>
          <el-carousel trigger="click" height="130px" indicator-position="none">
            <el-carousel-item v-for="item in pointinfo.imgList" :key="item.name" arrow="never">
              <img :src="item.url" style="width: 100%;height: 100%;" alt="" />
            </el-carousel-item>
          </el-carousel>
          <div class="info-motorcycle" style="margin: 10px 0 0 0;">
            <div>
              <img src="./../../assets/img/cabinet-l.png" alt="" />
              换电柜
            </div>
            <span>{{ pointinfo.stationOnlineTotal }}/{{ pointinfo.stationTotal }}台</span>
          </div>
          <div class="info-motorcycle" style="margin: 10px 0 0 0;">
            <div>
              <img src="./../../assets/img/battery-l.png" alt="" />
              可换电池
            </div>
            <span>{{ pointinfo.batteryOnlineTotal }}/{{ pointinfo.batteryTotal }}块</span>
          </div>
          <div class="info-motorcycle" style="margin: 10px 0 0 0;">
            <div>
              <img src="./../../assets/img/provider.png" alt="" />
              服务商
            </div>
            <span>{{ pointinfo.merchantName }}</span>
          </div>
          <div class="info-motorcycle" style="margin: 10px 0 0 0;">
            <div>
              <img src="./../../assets/img/time.png" alt="" />
              营业时间
            </div>
            <span>{{ pointinfo.timeText }}</span>
          </div>
          <div class="info-motorcycle" style="border-bottom: 0px;margin: 10px 0 0 0;">
            <div>
              <img src="./../../assets/img/address.png" alt="" />
              定位地址
            </div>
            <span>{{ pointinfo.address }}</span>
          </div>
        </div>
      </div>
      <!-- 近6月月消费里程/月充值里程  -->
      <div class="left-item">
        <div class="div-title">
          <div>
            {{ $t(`left1`) }}
            <el-tooltip class="item" effect="dark" :content="$t(`left1Explanation`)" placement="right-start">
              <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
            </el-tooltip>
          </div>
        </div>
        <seventActive></seventActive>
      </div>
      <!-- 近6月日活跃车辆/日换电车辆 -->
      <div class="left-item center">
        <div class="div-title">
          <div>
            {{ $t(`left2`) }}
            <el-tooltip class="item" effect="dark" :content="$t(`left2Explanation`)" placement="right-start">
              <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
            </el-tooltip>
          </div>
        </div>
        <orders></orders>
      </div>
    <!-- 近6月日换电次数/日换电充电柜数 -->
      <div class="left-item">
        <div class="div-title">
          <div>
            {{ $t(`left4`) }}
            <el-tooltip class="item" effect="dark" :content="$t(`left4Explanation`)" placement="right-start">
              <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
            </el-tooltip>
          </div>
        </div>
        <mothRecord></mothRecord>
      </div>
    </div>
    <div class="center">
      <!-- 谷歌地图 -->
      <div class="center-map">
        <div class="center-maps" id="kongzhishi-map"></div>
        <div id="centerid">
          <img src="./../../assets/img/newlocation.png" alt="" />
        </div>
        <div id="fullScreenBtn">
          <img src="https://app.swap.id/file/20215910125925.png" alt="" />
        </div>
        <div id="smallScreenBtn">
          <img src="https://app.swap.id/file/20215910125958.png" alt="" />
        </div>
        <div id="leftLegend" style="color: #fff" class="legend">
          <div class="H_el">
            <div class="legend-item legend-item0" :class="actItem == 1 ? 'legend-item0-act' : ''" @click="actItem=1, onMarker(1)">
              <img src="./../../assets/img/vehicle.png" alt="" />
              <div>
                <span>{{ $t(`centerMap1`) }} </span>
                <div v-if="rightData.bindingScooterCount">
                  {{ rightData.bindingScooterCount }}
                </div>
              </div>
            </div>
            <div class="legend-item legend-item1" :class="actItem == 0 ? 'legend-item1-act' : ''" @click="actItem=0,onMarker(0)">
              <img src="./../../assets/img/site.png" alt="" />
              <div>
                <span>{{ $t(`centerMap2`) }} </span>
                <div>{{ rightData.pointTotal }}</div>
              </div>
            </div>
            <div class="legend-item legend-item2" :class="actItem == 5 ? 'legend-item2-act' : ''" @click="actItem=5,onMarker(5)">
              <img src="./../../assets/img/battery1.png" alt="" />
              <div>
                <span>{{ $t(`centerMap3`) }} </span>
                <div>{{ rightData.batteryTotal }}</div>
              </div>
            </div>
            <div class="legend-item legend-item3" :class="actItem == 2 ? 'legend-item3-act' : ''" @click="actItem=2,onMarker(2)">
              <img src="./../../assets/img/cabinet.png" alt="" />
              <div>
                <span>{{ $t(`centerMap4`) }}</span>
                <div v-if="rightData.displayStationCount">
                  {{ rightData.displayStationCount }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="toggleData" class="toggleData">
          <div class="H_el">
            <div id="showtoggle">
              <div class="toggle-item" @click="actItem=0,onMarker(0)" style="cursor: pointer;">{{ $t(`centerMap5`) }}</div>
              <div class="toggle-item" @click="actItem=5,onMarker(5)" style="cursor: pointer;">{{ $t(`centerMap6`) }}</div>
              <div class="toggle-item" @click="actItem=1,onMarker(1)" style="cursor: pointer;">{{ $t(`centerMap7`) }}</div>
              <div class="toggle-item" @click="actItem=2,onMarker(2)" style="cursor: pointer;">{{ $t(`centerMap8`) }}</div>
            </div>
            <div class="toggle-item toggle-item-active" @click="onMarker(3)" style="cursor: pointer;">
              <span id="toggle-item-active">{{ $t(`centerMap9`) }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </div>
        <!-- <div class="center-maps" id="mapContainer"></div>
        <div v-show="false">
          <div id="legend" style="color: #fff" v-show="rightData.moTotal">
            <div class="legend-item legend-item0" onclick=onMarker(1)>
              <img src="./../../assets/img/vehicle.png" alt="" />
              <div>
                <span>车辆</span>
                <div v-if="rightData.moTotal">
                  {{rightData.moTotal}}
                </div>
              </div>
            </div>
            <div class="legend-item legend-item1" onclick=onMarker(0)>
              <img src="./../../assets/img/site.png" alt="" />
              <div>
                <span>站点</span>
                <div>0</div>
              </div>
            </div>
            <div class="legend-item legend-item2">
              <img src="./../../assets/img/battery1.png" alt="" />
              <div>
                <span>电池数</span>
                <div>{{rightData.batteryTotal}}</div>
              </div>
            </div>
            <div class="legend-item legend-item3" onclick=onMarker(2)>
              <img src="./../../assets/img/cabinet.png" alt="" />
              <div>
                <span>换电柜</span>
                <div v-if="rightData.stationTotal">
                  {{rightData.stationTotal}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="false">
          <div id="toggleData">
            <div id="showtoggle">
              <div class="toggle-item" onclick=onMarker(0)>只显示站点</div>
              <div class="toggle-item" onclick=onMarker(1)>只显示电动车</div>
              <div class="toggle-item" onclick=onMarker(2)>只显示换电柜</div>
            </div>
            <div class="toggle-item toggle-item-active" onclick=onMarker(3)>
              <span id="toggle-item-active">全部</span>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </div> -->
        <!-- <div class="center-maps" id="kongzhishi-map"></div> -->
        <!-- <div id="centerid"><img src="./../../assets/img/location.png" alt=""></div>
				<div id="fullScreenBtn">
					<img src="https://app.swap.id/file/20215910125925.png" alt="">
				</div>
				<div id="smallScreenBtn">
					<img src="https://app.swap.id/file/20215910125958.png" alt="">
				</div> -->
        <!-- <div class="boxfoot"></div> -->
      </div>
      <!-- 近6月月充值的订单/月订单总额 -->
      <div class="center-hour">
        <div class="center-hour-item">
          <div class="div-title">
            <div>
              {{ this.$t(`left3`) }}
              <el-tooltip class="item" effect="dark" :content="$t(`left3Explanation`)" placement="right-start">
                <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
              </el-tooltip>
            </div>
          </div>
          <seventScooter></seventScooter>
        </div>
        <!-- 历史24小时换电高峰 -->
        <div class="center-hour-item">
          <div class="div-title">
            <div>
              {{ $t(`content1`) }}
              <el-tooltip class="item" effect="dark" :content="$t(`content1Explanation`)" placement="right-start">
                <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
              </el-tooltip>
            </div>
          </div>
          <hours></hours>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right-top">
        <!-- 实时统计 -->
        <div class="div-title">{{ this.$t(`right1`) }}</div>
        <div class="middle_top">
          <!-- 累计里程(km) -->
          <div class="middle_toplist">
            <div class="middle_toplist_title">{{ this.$t(`right2`) }}(km)</div>
            <div class="list_toplist_num color_mi">
              <div v-show="oldConsumeSumTotal==0">-</div>
              <countTo v-show="oldConsumeSumTotal>0" :startVal="oldConsumeSumTotal" :endVal="rightData.lichengTotal" :duration="duration"
                :decimals="decimals"> </countTo>
            </div>
            <div class="today_toplist_font" style="display: flex">
              <span class="color_mi">{{ Thousands(rightData.consumeValueCount) }}</span>
              <div :style="rightData.consumeRingRatio > 0 ? 'color: red' : 'color: green'">
                <i :class="rightData.consumeRingRatio > 0 ? 'el-icon-top' : 'el-icon-bottom'"
                  style="font-size: 14px; margin-left: 2px;"></i>
                {{ rightData.consumeRingRatio + "%" }}
              </div>
            </div>
            <div class="middle_toplist_title">
              ({{ $t(`right26`) }})
            </div>
          </div>
          <!-- 累计订单总额(Rp) -->
          <div class="middle_toplist">
            <div class="middle_toplist_title">{{ this.$t(`right4`) }}(Rp)</div>
            <div class="list_toplist_num color_order">
              <div v-show="oldMoneyOrderTotal == 0">-</div>
              <countTo v-show="oldMoneyOrderTotal > 0" :startVal="oldMoneyOrderTotal" :endVal="rightData.moneyTotal" :duration="duration"
                :decimals="decimals"> </countTo>
            </div>
            <div class="today_toplist_font" style="display: flex">
              <span class="color_order">{{ Thousands(rightData.orderAmountCount) }}</span>
              <div :style="rightData.orderRingRatio > 0 ? 'color: red' : 'color: green'">
                <i :class="rightData.orderRingRatio > 0 ? 'el-icon-top' : 'el-icon-bottom'"
                  style="font-size: 14px; margin-left: 2px;"></i>
                {{ rightData.orderRingRatio + "%" }}
              </div>
            </div>
            <div class="middle_toplist_title">
              ({{ $t(`right26`) }})
            </div>
          </div>
          <!-- 用户总数 -->
          <div class="middle_toplist">
            <div class="middle_toplist_title">{{ this.$t(`right17`) }}</div>
            <div class="list_toplist_num color_user">
              <countTo :startVal="startVal" :endVal="rightData.userTotal" :duration="duration" :decimals="decimals">
              </countTo>
            </div>
            <div class="today_toplist_font">
              <el-tooltip class="item" effect="dark" content="统计当前系统上已绑定车辆的用户数总和。" placement="right-start">
                <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
              </el-tooltip>
              {{ this.$t(`right11`) }}：<span class="color_user">{{
                rightData.activeUserCount
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right-bottom">
        <!-- 设备数据 -->
        <div class="div-title" style="top: 0.5rem">{{ this.$t(`right9`) }}</div>
        <div class="con_items">
          <!-- 电动车总量 -->
          <div class="con_item">
            <div>{{ this.$t(`right10`) }}</div>
            <div class="content_number">
              <div class="con_item_icon">
                <img src="./../../assets/img/scooter.png" class="" />
              </div>
              <div class="number-all color_mi">
                <countTo :startVal="startVal" :endVal="rightData.scooterTotal" :duration="duration" :decimals="decimals">
                </countTo>
              </div>
            </div>
            <div>
              <el-tooltip class="item" effect="dark" content="统计近30天已绑定用户且产生骑行记录的车辆数总和。" placement="right-start">
                <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
              </el-tooltip>
              {{ this.$t(`right11`) }}：<span class="font_clip_color color_mi">{{ Thousands(rightData.activeScooterCount)
              }}</span>
            </div>
            <div>
              <el-tooltip class="item" effect="dark" content="统计当前系统上已绑定用户的车辆数总和。" placement="right-start">
                <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
              </el-tooltip>
              {{ this.$t(`Alive`) }}：<span class="font_clip_color color_mi">{{
                Thousands(rightData.bindingScooterCount)
              }}</span>
            </div>
          </div>
          <!-- 换电柜总量 -->
          <div class="con_item">
            <div>{{ this.$t(`right20`) }}</div>
            <div class="content_number">
              <div class="con_item_icon">
                <img src="./../../assets/img/station.png" class="" />
              </div>
              <div class="number-all color_user">
                <countTo :startVal="startVal" :endVal="rightData.stationTotal" :duration="duration" :decimals="decimals">
                </countTo>
              </div>
            </div>
            <div>
              <el-tooltip class="item" effect="dark" content="统计当前系统上在运营且近30天产生换电记录的换电柜数总和。" placement="right-start">
                <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
              </el-tooltip>
              {{ this.$t(`right11`) }}：<span class="font_clip_color color_user">{{ Thousands(rightData.activeStationCount)
              }}</span>
            </div>
            <div>
              <el-tooltip class="item" effect="dark" content="统计当前系统上在运营（展示在用户app地图上）的换电柜数总和。" placement="right-start">
                <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
              </el-tooltip>
              {{ this.$t(`online`) }}：<span class="font_clip_color color_user">{{ Thousands(rightData.displayStationCount)
              }}</span>
            </div>
          </div>
          <!-- 电池总量 -->
          <div class="con_item">
            <div>{{ this.$t(`right19`) }}</div>
            <div class="content_number">
              <div class="con_item_icon">
                <img src="./../../assets/img/battery.png" class="" />
              </div>
              <div class="number-all color_order">
                <countTo :startVal="startVal" :endVal="rightData.batteryTotal" :duration="duration" :decimals="decimals">
                </countTo>
              </div>
            </div>
            <div>
              <el-tooltip class="item" effect="dark" content="近30天新增(出库印尼)电池数" placement="right-start">
                <i class="el-icon-warning-outline" style="font-size: 14px; margin-left: 2px"></i>
              </el-tooltip>
              {{ this.$t(`right12`) }}：<span class="font_clip_color color_order">{{ Thousands(rightData.addBatteryCount)
              }}</span>
            </div>
          </div>
        </div>
        <div class="line_x" style="width：100%;height:0.05rem;"></div>
        <div class="intro">
          <div class="pie_intro">
            <div style="color: #0dccff">
              {{ this.$t(`inventoryRatioInfo.Warehouse`) }}
            </div>
            <div class="pie_size bgc_W"></div>
          </div>
          <div class="pie_intro">
            <div style="color: #2dd660">
              {{ this.$t(`inventoryRatioInfo.Operation`) }}
            </div>
            <div class="pie_size bgc_O"></div>
          </div>
          <div class="pie_intro">
            <div style="color: #ffa734">
              {{ this.$t(`inventoryRatioInfo.Maintenance`) }}
            </div>
            <div class="pie_size bgc_M"></div>
          </div>
        </div>
        <div style="display: flex;
          justify-content: space-around;
          width: 100%;
          margin-top: 0.1rem;
        ">
          <!-- 电动车库存占比 -->
          <div class="item-pie" v-if="scooterPercentage.indonesianMaintenance">
            <location :number="location_number" :percentageInfo="scooterPercentage" id="scooter"></location>
            <div class="item-pie-title">
              {{ this.$t(`rightBottomOne.scooterLocation`) }}
            </div>
          </div>
          <!-- 电池库存占比 -->
          <div class="item-pie" v-if="batteryPercentage.indonesianMaintenance">
            <location :number="location_number" :percentageInfo="batteryPercentage" id="battery"></location>
            <div class="item-pie-title">
              {{ this.$t(`rightBottomOne.batteryLocation`) }}
            </div>
          </div>
          <!-- 换电柜库存占比 -->
          <div class="item-pie" v-if="stationPercentage.indonesianMaintenance">
            <location :number="location_number" :percentageInfo="stationPercentage" id="station"></location>
            <div class="item-pie-title">
              {{ this.$t(`rightBottomOne.stationLocation`) }}
            </div>
          </div>
        </div>
      </div>
      <div class="right-bottom2">
        <!-- 历史换电电量频次表 -->
        <div class="left-item" style="height: 100%">
          <div class="div-title" style="top: 0.2rem">
            <div>
              {{ this.$t(`right24`) }}
            </div>
          </div>
          <powerFrequency></powerFrequency>
        </div>
        <div class="notice" style="display: none">
          <div class="notice-item">
            <div>{{ this.$t(`right22`) }}<span>12</span></div>
          </div>
          <div class="notice-item">
            <div>{{ this.$t(`right23`) }}<span>2</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import foowwLocalStorage from "../../utils/foow.js";
// import E from "wangeditor";
require("echarts/extension/bmap/bmap");
// const CUSTOM_MAP_CONFIG = require("../../static/custom_map_config.json");
// import GoogleMapsLoader from "google-maps";
import rateView from "./compents/rate.vue";
import CountTo from "vue-count-to"
import {
  getHouersData,
  getScooterMapData,
  getRightData,
  getScooterdetail,
  getPointBatteryNum,
  getPointLocation,
  getPointDetail,
  getPointSearch,
  getDeviceData,
  getPercentage
} from "../../request/conlog.js";
import seventScooter from "./compents/seventScooter";
import seventActive from "./compents/seventActive";
import mothRecord from "./compents/mothRecord";
import orders from "./compents/orders";
import hours from "./compents/hours";
import location from "./compents/location.vue";
import powerFrequency from "./compents/powerFrequency.vue";
export default {
  name: "index",
  components: {
    rateView,
    seventScooter,
    seventActive,
    orders,
    hours,
    location,
    mothRecord,
    powerFrequency,
    CountTo
  },
  props: {
    typename: {
      type: Number,
      default: 0,
    },
    screenfullq: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ui: undefined,
      hereMap: undefined,
      testone: 1,
      location_number: 2,
      map: null,
      dateTimeEleDatas: [], //实时数据
      dayTimeEleDatas: [], //今日实时
      dateTime: [12, 5, 9, 18, 4, 91, 9, 3, 9],
      dayTime: [12, 5, 9, 18, 4, 91, 9, 3, 9], //今日实时
      //地图电动车和换电柜
      mapData: [2, 1, 3, 2],
      //图表配置信息
      options: {
        elecar: null, //电动车在线与离线饼图
        huan: null, //换电柜在线与离线饼图
        battery: null, //电池在线与离线饼图
        mileage: null, //近七天里程
        cycling: null, //近七天骑行车辆
        orders: null, //近七天订单
        hours: null, //24小时
      },
      rate00: 0,
      rate01: 0,
      rate02: 0,
      rate: [
        {
          id: "centerRate1",
          tips: 6,
          all: 1005,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5",
              },
            },
          },
        },
        {
          id: "centerRate2",
          tips: 40,
          all: 1300,
          colorData: {
            textStyle: "#ff9800",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad",
              },
            },
          },
        },
        {
          id: "centerRate3",
          tips: 80,
          all: 1090,
          colorData: {
            textStyle: "#48ff00",
            series: {
              color: ["#befaa378", "transparent"],
              dataColor: {
                normal: "#48ff00",
                shadowColor: "#e4fcad",
              },
            },
          },
        },
      ],
      //七天数据
      WeekConsume: {},
      weekConsumeScooter: {},
      weekOrder: {},
      //实时统计
      times: {
        licheng: {
          total: "",
          addToday: "",
        },
      },
      rightData: {
        lichengTotal: 21624503,
        moneyTotal: 3833747759,
        orderRingRatio: undefined,
        consumeValueCount: undefined,
        activeUserCount: undefined,
        userTotal: undefined,
        consumeRingRatio: undefined,
        orderAmountCount: undefined,
        bindingScooterCount: undefined,
        activeStationCount: undefined,
        activeScooterCount: undefined,
        pointTotal: undefined,
        displayStationCount: undefined,
        batteryTotal: undefined,
        stationTotal: undefined,
        addBatteryCount: undefined,
        scooterTotal: undefined,
      },
      scooterPercentage: {},
      stationPercentage: {},
      batteryPercentage: {},
      offlineScooterClusteringLayer: undefined,
      onlineScooterClusteringLayer: undefined,
      offlineStationClusteringLayer: undefined,
      onlineStationClusteringLayer: undefined,
      logContainer: undefined,
      scooterData: undefined,
      zoom: undefined,
      toggleDataText: "全部",
      show: true,
      info: undefined,
      pointinfo: undefined,
      status: undefined,
      zoomToggle: false,
      scooterMarkers: [],
      stationMarkers: [],
      platform: undefined,
      marker: undefined,
      markerList: [],
      flag: 1,
      startVal: 0, // 初始值
      endVal: 12345, //最终值
      duration: 1300, //需要滚动的时间
      decimals: 0, //保留小数位数
      userId: undefined,
      source: undefined,
      geocoder: undefined,
      infowindow: undefined,
      pointBatteryNumList: undefined,
      offlineScooter: undefined,
      onlineScooter: undefined,
      searchText: '',
      searchShow: 2,
      markersPointLocation: [],
      markersScooter: [],
      markersStation: [],
      pointSearchList: [],
      oldSearchText: '',
      oldConsumeSumTotal: 0,
      oldMoneyOrderTotal: 0,
      actItem: 0
    };
  },
  computed: {},
  created() {
    //动态绑定点击事件
    let _this = this;
    window.onMarker = _this.onMarker;
  },
  mounted() {
    let that = this;
    // let token = foowwLocalStorage.get('base-token')
    let source = null;
    // 用时间戳模拟登录用户
    const userId = new Date().getTime();
    this.userId = userId;
    if (window.EventSource) {
      // 建立连接
       //source = new EventSource('http://192.168.5.111:8000/sse/connect?userId=' + userId);
      //source = new EventSource('https://data.swap.id/api/sse/connect?userId=' + userId);
     source = new EventSource('https://data.atnsmart.com/data/api/sse/connect?userId=' + userId);
   // source = new EventSource('http://8.134.167.149:8000/ap/sse/connect?userId=' + userId);
      this.source = source
      /**
       * 连接一旦建立，就会触发open事件
       * 另一种写法：source.onopen = function (event) {}
       */
      source.addEventListener('open', function (e) {
        console.log("建立连接。。。");
      }, false);
      /**
       * 客户端收到服务器发来的数据
       * 另一种写法：source.onmessage = function (event) {}
       */
      source.addEventListener('message', function (e) {
        const data = JSON.parse(e.data);
        // if (that.rightData.lichengTotal) {
        //   that.oldConsumeSumTotal = that.rightData.lichengTotal
        // }
        let math =  require('mathjs')
        that.rightData.lichengTotal = math.random() * 203514;
        // that.rightData.orderTotal = parseInt(data.orderTotal);reduceConsume
        // if (that.rightData.moneyTotal) {
        //   that.oldMoneyOrderTotal = that.rightData.moneyTotal
        // }
        that.rightData.moneyTotal = math.random() * 358924;
        let reduceConsume = parseFloat(data.reduceConsume);
        let electricityTotal = parseInt(data.electricityTotal);
        that.$store.commit("login/setOldReduceConsume", that.$store.state.login.reduceConsume);
        that.$store.commit("login/setOldElectricityTotal", that.$store.state.login.electricityTotal);
        that.$store.commit("login/setReduceConsume", reduceConsume);
        that.$store.commit("login/setElectricityTotal", electricityTotal);
      });
      /**
       * 如果发生通信错误（比如连接中断），就会触发error事件
       * 或者：
       * 另一种写法：source.onerror = function (event) {}
       */
      source.addEventListener('error', function (e) {
        if (e.readyState === EventSource.CLOSED) {
          console.log("连接关闭");
        } else {
          console.log(e);
        }
      }, false);
    } else {
      console.log("你的浏览器不支持SSE");
    }
    // 监听窗口关闭事件，主动去关闭sse连接，如果服务端设置永不过期，浏览器关闭后手动清理服务端数据
    window.onbeforeunload = function () {
      this.closeSse();
    };
    // //here地图图标
    // var platform = new H.service.Platform({
    //   apikey: "DAoN89zyIIZgfglerE8XZSgL2-oDO-sAhlKYKSxx6LE",
    // });
    // this.platform = platform;
    // var maptypes = platform.createDefaultLayers();

    // this.hereMap = new H.Map(
    //   document.getElementById("mapContainer"),
    //   maptypes.vector.normal.map,
    //   {
    //     zoom: 12,
    //     center: { lng: 106.82721, lat: -6.17576 },
    //   }
    // );
    // var mapEvents = new H.mapevents.MapEvents(this.hereMap);

    // this.hereMap.addEventListener("tap", function (evt) {
    //   //   console.log(evt.type, evt.currentPointer.type);
    // });

    // var behavior = new H.mapevents.Behavior(mapEvents);
    // var ui = H.ui.UI.createDefault(this.hereMap, maptypes);
    // this.zoom = ui.getControl("zoom");
    // ui.removeControl("zoom");
    // ui.removeControl("mapsettings");
    // ui.removeControl("scalebar");
    // this.ui = ui;
    // this.mapsFullScreenControl(ui);
    // this.showGeoJSONData(this.hereMap);

    //右边面板数据
    getRightData({}).then((res) => {
      if (res.errcode === 200) {
        const data = res.data;
        this.rightData.orderRingRatio = data.orderRingRatio;
        this.rightData.consumeValueCount = data.consumeValueCount;
        this.rightData.activeUserCount = data.activeUserCount;
        this.rightData.userTotal = data.userTotal;
        this.rightData.consumeRingRatio = data.consumeRingRatio;
        this.rightData.orderAmountCount = data.orderAmountCount;
      }
    });
    getDeviceData().then((res) => {
      const data = res.data;
      this.rightData.bindingScooterCount = data.bindingScooterCount;
      this.rightData.activeStationCount = data.activeStationCount;
      this.rightData.activeScooterCount = data.activeScooterCount;
      this.rightData.pointTotal = data.pointTotal;
      this.rightData.displayStationCount = data.displayStationCount;
      this.rightData.batteryTotal = parseInt(data.batteryTotal);
      this.rightData.stationTotal = data.stationTotal;
      this.rightData.addBatteryCount = data.addBatteryCount;
      this.rightData.scooterTotal = data.scooterTotal;
    })
    getPercentage().then((res) => {
      const data = res.data;
      this.scooterPercentage = res.data.scooter;
      this.stationPercentage = res.data.station;
      this.batteryPercentage = res.data.battery;
    })

    //this.resize();
    this.canves();
    window.resize = () => {
      return (() => {
      })();
    };
  },
  methods: {
    clearSearch() {
      this.pointSearchList = []
    },
    toSearch() {
      if ((this.searchText != '') && (this.searchText != this.oldSearchText)) {
        this.oldSearchText = this.searchText
        getPointSearch({
          search: this.searchText
        }).then(res => {
          this.pointSearchList = res.data.point
        })
      }
    },
    closeSse() {
      this.source.close();
      const httpRequest = new XMLHttpRequest();
      // httpRequest.open('GET', 'http://192.168.5.111:8000/sse/close?userId=' + this.userId, true);
      httpRequest.open('GET', 'https://data.swap.id/api/sse/close?userId=' + this.userId, true);
      httpRequest.send();
    },
    // showGeoJSONData(map) {
    //   var reader = new H.data.geojson.Reader("/utils/Indonesia", {
    //     style: function (mapObject) {
    //       if (mapObject instanceof H.map.Polygon) {
    //         mapObject.setStyle({
    //           fillColor: "rgba(255, 0, 0, 0.5)",
    //           strokeColor: "rgba(0, 0, 255, 0.2)",
    //           lineWidth: 3,
    //         });
    //       }
    //     },
    //   });
    //   reader.parse();
    //   map.addLayer(reader.getLayer());
    // },
    // GetPercent(num, total) {
    //   num = parseFloat(num);
    //   total = parseFloat(total);
    //   if (isNaN(num) || isNaN(total)) {
    //     return "-";
    //   }
    //   return total <= 0 ? "0" : Math.round((num / total) * 10000) / 100.0;
    // },
    resize() {
      this.resizeFunc(1920, 1080);
    },
    resizeFunc(designWidth, designHeight) {
      $("body").css("width", designWidth + "px");
      $("body").css("height", designHeight + "px");
      $("#app").css("width", designWidth + "px");
      $("#app").css("height", designHeight + "px");
      // let { availWidth, availHeight } = window.screen;
      let availWidth = window.innerWidth;
      let availHeight = window.innerHeight;
      $("html").css("width", availWidth + "px");
      $("html").css("height", availHeight + "px");
      // 页面宽度缩放
      var rx = availWidth / designWidth;
      // 页面高度缩放
      var ry = availHeight / designHeight;
      // 页面宽度偏移量
      var lx = -(designWidth - availWidth) / 2;
      // 页面高度偏移量
      var ly = -(designHeight - availHeight) / 2;
      $("body").css(
        "transform",
        "matrix(" + rx + ",0,0," + ry + "," + lx + "," + ly + ")"
      );
    },
    // drawLayer03Right(canvasObj, colorValue, rate) {
    //   var ctx = canvasObj.getContext("2d");
    //   var circle = {
    //     x: 105, //圆心的x轴坐标值
    //     y: 140, //圆心的y轴坐标值
    //     r: 60, //圆的半径
    //   };
    //   //画扇形
    //   //ctx.sector(circle.x,circle.y,circle.r,1.5*Math.PI,(1.5+rate*2)*Math.PI);
    //   //ctx.fillStyle = colorValue;
    //   //ctx.fill();
    //   ctx.beginPath();
    //   ctx.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2);
    //   ctx.lineWidth = 10;
    //   ctx.strokeStyle = "#052639";
    //   ctx.stroke();
    //   ctx.closePath();
    //   ctx.beginPath();
    //   ctx.arc(
    //     circle.x,
    //     circle.y,
    //     circle.r,
    //     1.5 * Math.PI,
    //     (1.5 + rate * 2) * Math.PI
    //   );
    //   ctx.lineWidth = 10;
    //   ctx.lineCap = "round";
    //   ctx.strokeStyle = colorValue;
    //   ctx.stroke();
    //   ctx.closePath();
    //   ctx.fillStyle = "white";
    //   ctx.font = "20px Calibri";
    //   ctx.fillText(rate * 100 + "%", circle.x - 15, circle.y + 10);
    // },
    // setRealData() {
    //   const data = [12, 5, 9, 18, 4, 91, 9, 3];
    //   setTimeout(() => {
    //     for (let i = 0; i < data.length; i++) {
    //       data[i] += 1;
    //     }
    //   }, 1000);
    //   this.dateTimeEleDatas = [
    //     {
    //       name: this.$t("console.titleOne.type1"),
    //       number: data[0],
    //     },
    //     {
    //       name: this.$t("console.titleOne.type2"),
    //       number: data[1],
    //     },
    //     {
    //       name: this.$t("console.titleOne.type3"),
    //       number: data[2],
    //     },
    //     {
    //       name: this.$t("console.titleOne.type4"),
    //       number: data[3],
    //     },
    //     {
    //       name: this.$t("console.titleOne.type5"),
    //       number: data[4],
    //     },
    //     {
    //       name: this.$t("console.titleOne.type6"),
    //       number: data[5],
    //     },
    //     {
    //       name: this.$t("console.titleOne.type7"),
    //       number: data[6],
    //     },
    //     {
    //       name: this.$t("console.titleOne.type8"),
    //       number: data[7],
    //     },
    //     {
    //       name: this.$t("console.titleOne.type9"),
    //       number: data[8],
    //     },
    //   ];
    // },
    timerone() {
      return setTimeout(() => {
        //	this.getData()
      }, 1000);
    },
    // milliFormat(num) {
    //   return (
    //     num &&
    //     num.toString().replace(/\d+/, function (s) {
    //       return s.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    //     })
    //   );
    // },
    //画图表
    async canves() {
      //this.setNumberDatas(); //近七天里程
      //this.setQiCheNumberDatas(); //近七天骑车车辆  饼形图
      //this.setHuanElement();//24小时换电高峰
      //this.setOrders(); //近七天订单
      this.setkongDatas(); //地图  活动电动车  上线电线柜
      //this.setDatasContent(); //三个扇形图
    },
    //设置信息框
    // CenterControl(controlDiv, map) {
    //   //单个白色
    //   const controlUIP = document.createElement("div");
    //   controlUIP.style.display = "flex";
    //   // controlUIP.style.backgroundColor="#021114";
    //   controlUIP.style.marginTop = "4px";
    //   //电动车
    //   const controlUI = document.createElement("div");
    //   controlUI.style.borderRadius = "3px";
    //   controlUI.style.backgroundColor = "#021114";
    //   controlUI.style.fontSize = "14px";
    //   controlUI.style.color = "#fff";
    //   controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    //   controlUI.style.cursor = "pointer";
    //   controlUI.style.marginTop = "8px";
    //   controlUI.style.textAlign = "center";
    //   controlUI.style.padding = "15px";
    //   controlUI.style.marginRight = "50px";
    //   controlUI.innerHTML = "活跃电动车";
    //   //数字
    //   const controlText = document.createElement("div");
    //   controlText.style.color = "#fff";
    //   controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    //   controlText.style.backgroundColor = "#021114";
    //   controlText.style.fontSize = "16px";
    //   controlText.style.lineHeight = "34px";
    //   controlText.style.paddingLeft = "5px";
    //   controlText.style.paddingRight = "5px";
    //   controlText.style.marginTop = "5px";
    //   for (let i = 0; i < 3; i++) {
    //     const controlTexts1 = document.createElement("span");
    //     controlTexts1.style.color = "#0092ab";
    //     controlTexts1.innerHTML = "8";
    //     controlTexts1.style.backgroundColor = "#092126";
    //     controlTexts1.style.padding = "3px";
    //     controlTexts1.style.fontSize = "20px";
    //     controlTexts1.style.margin = "0 1px";
    //     controlText.appendChild(controlTexts1);
    //   }
    //   controlUI.appendChild(controlText);
    //   //换电柜
    //   const controlUI2 = document.createElement("div");
    //   controlUI2.style.borderRadius = "3px";
    //   controlUI2.style.fontSize = "14px";
    //   controlUI2.style.color = "#fff";
    //   controlUI2.style.backgroundColor = "#021114";
    //   controlUI2.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    //   controlUI2.style.cursor = "pointer";
    //   controlUI2.style.marginTop = "8px";
    //   controlUI2.style.textAlign = "center";
    //   controlUI2.style.marginLeft = "50px";
    //   controlUI2.style.padding = "15px";
    //   controlUI2.innerHTML = "换电柜";
    //   const controlText2 = document.createElement("div");
    //   controlText2.style.color = "#fff";
    //   controlText2.style.fontFamily = "Roboto,Arial,sans-serif";
    //   controlText2.style.backgroundColor = "#021114";
    //   controlText2.style.fontSize = "16px";
    //   controlText2.style.lineHeight = "34px";
    //   controlText2.style.paddingLeft = "5px";
    //   controlText2.style.paddingRight = "5px";
    //   controlText2.style.marginTop = "5px";
    //   for (let i = 0; i < 3; i++) {
    //     const controlTexts2 = document.createElement("span");
    //     controlTexts2.style.color = "#0092ab";
    //     controlTexts2.innerHTML = "8";
    //     controlTexts2.style.backgroundColor = "#092126";
    //     controlTexts2.style.padding = "3px";
    //     controlTexts2.style.fontSize = "20px";
    //     controlTexts2.style.margin = "0 1px";
    //     controlText2.appendChild(controlTexts2);
    //   }
    //   controlUI2.appendChild(controlText2);
    //   controlUIP.appendChild(controlUI);
    //   controlUIP.appendChild(controlUI2);
    //   controlDiv.appendChild(controlUIP);
    // },
    //设置标点信息
    // setMarkers(map) {
    //   const beaches = [
    //     ["Bondi Beach", -33.890542, 151.274856, 4],
    //     ["Coogee Beach", -33.923036, 151.259052, 5],
    //     ["Cronulla Beach", -34.028249, 151.157507, 3],
    //     ["Manly Beach", -33.80010128657071, 151.28747820854187, 2],
    //     ["Maroubra Beach", -33.950198, 151.259302, 1],
    //   ];
    //   //图标标记
    //   const image = {
    //     url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
    //     size: new google.maps.Size(20, 32),
    //     origin: new google.maps.Point(0, 0),
    //     anchor: new google.maps.Point(0, 32),
    //   };
    //   const shape = {
    //     coords: [1, 1, 1, 20, 18, 20, 18, 1],
    //     type: "poly",
    //   };
    //   const infoWindow = new google.maps.InfoWindow();
    //   for (let i = 0; i < beaches.length; i++) {
    //     const beach = beaches[i];
    //     const marker = new google.maps.Marker({
    //       position: { lat: beach[1], lng: beach[2] },
    //       map,
    //       icon: image,

    //       title: beach[0] + " " + beach[3],
    //       optimized: false,
    //       zIndex: beach[3],
    //     });
    //     marker.addListener("click", () => {
    //       infoWindow.close();
    //       infoWindow.setContent(marker.getTitle());
    //       infoWindow.open(marker.getMap(), marker);
    //     });
    //   }
    // },
    //千分数字处理
    Thousands(num) {
      var result = [],
        counter = 0;
      num = (num || 0).toString().split("");
      for (var i = num.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(num[i]);
        if (!(counter % 3) && i != 0) {
          result.unshift(",");
        }
      }
      return result.join("");
    },
    //设置图列位置
    setShowData() {
      var that = this;
      const map = this.map
      this.status = [
        {
          number: 0,
          img: "https://app.swap.id/file/20212107132138.png",
          name: "Online",
        },
        {
          number: 0,
          img: "https://app.swap.id/file/20212007132035.png",
          name: "Offline",
        },
        {
          number: 0,
          img: "https://app.swap.id/file/20212107132108.png",
          name: "Online",
        },
        {
          number: 0,
          img: "https://app.swap.id/file/20212307132307.png",
          name: "Offline",
        },
      ];
      //电动车
      let markerCluster = new markerClusterer.MarkerClusterer({ map, markers: [] });
      this.markerCluster = markerCluster
      getScooterMapData().then((res) => {
        if (res.errcode === 200) {
          let markersScooter = []
          const data = res.data;
          this.status[0].number = data.onlineScooterCount;
          this.status[1].number = data.offlineScooterCount;
          const offlineScooter = data.offlineScooter;
          const onlineScooter = data.onlineScooter;
          this.offlineScooter = offlineScooter
          this.onlineScooter = onlineScooter
          //创建谷歌地图图标
          let icon = {
            url: 'https://i.postimg.cc/KvBXBkmB/3-63.png',
            // url:require('./../../assets/img/dcar3.png'),
            scaledSize: new google.maps.Size(27, 49), // scaled size
            // origin: new google.maps.Point(0,0), // origin
            // anchor: new google.maps.Point(0, 0) // anchor
          }
          offlineScooter.forEach((item, index) => {
            //谷歌地图添加图标添加点击事件
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude)),
              // offset: new AMap.Pixel(-10, -10),
              icon: icon,
              // map: this.map,
              title: item.scooterCode,
              // optimized: false,
              animation: null,
            });
            marker.addListener('click', function () {
              that.getScooterdetailInfo(marker.getTitle(), { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }, marker)
            })
            markersScooter.push(marker)
          });
          onlineScooter.forEach((item, index) => {
            //谷歌地图添加图标添加点击事件
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude)),
              // offset: new AMap.Pixel(-10, -10),
              icon: icon,
              // map: this.map,
              title: item.scooterCode,
              // optimized: false,
              animation: null,
            });
            marker.addListener('click', function () {
              that.getScooterdetailInfo(marker.getTitle(), { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }, marker)
            })
            markersScooter.push(marker)
          });
          // markerCluster.addMarkers(markersScooter, true);
          this.markersScooter = markersScooter;
        }
      });
      //点位
      getPointLocation().then((res) => {
        if (res.errcode === 200) {
          let markersPointLocation = []
          let pointLocation = []
          const data = res.data;
          const offlinePoint = data.offlinePoint;
          const onlinePoint = data.onlinePoint;
          //创建谷歌地图图标
          let icon = {
            url: 'https://i.postimg.cc/kXrzJJW4/3-88.png',
            scaledSize: new google.maps.Size(27, 49), // scaled size
            // origin: new google.maps.Point(0,0), // origin
            // anchor: new google.maps.Point(0, 0) // anchor
          }
          offlinePoint.forEach((item, index) => {
            //谷歌地图添加图标添加点击事件
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude)),
              // offset: new AMap.Pixel(-10, -10),
              icon: icon,
              map: this.map,
              title: item.pointId,
              animation: null,
            });
            marker.addListener('click', function () {
              that.getPointInfo(marker.getTitle(), { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }, marker)
            })
            markersPointLocation.push(marker)
          });
          onlinePoint.forEach((item, index) => {
            //谷歌地图添加图标添加点击事件
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude)),
              // offset: new AMap.Pixel(-10, -10),
              icon: icon,
              map: this.map,
              title: item.pointId,
              animation: null,
            });
            marker.addListener('click', function () {
              that.getPointInfo(marker.getTitle(), { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }, marker)
            })
            markersPointLocation.push(marker)
          });
          markerCluster.addMarkers(markersPointLocation, true);
          this.markersPointLocation = markersPointLocation;
        }
      });
      //换电柜
      // getStationMapData().then((res) => {
      //   if (res.errcode === 200) {
      //     let markersStation = []
      //     const data = res.data;
      //     this.status[2].number = data.onlineStationCount;
      //     this.status[3].number = data.offlineStationCount;
      //     const offlineStation = data.offlineStation;
      //     const onlineStation = data.onlineStation;
      //     //创建谷歌地图图标
      //     let icon = {
      //       url:'https://i.postimg.cc/kXrzJJW4/3-88.png',
      //       scaledSize: new google.maps.Size(27, 49), // scaled size
      //       origin: new google.maps.Point(0,0), // origin
      //       anchor: new google.maps.Point(0, 0) // anchor
      //     }
      //     offlineStation.forEach((item, index) => {
      //       //谷歌地图添加图标添加点击事件
      //       const marker = new google.maps.Marker({
      //       	position: new google.maps.LatLng(parseFloat(item.latitude),parseFloat(item.longitude)),
      //       	icon: icon,
      //       	map: this.map,
      //         title: item.stationCode,
      //       });
      //       marker.addListener('click', function(){
      //         that.getPointInfo(marker.getTitle(), {lat: parseFloat(item.latitude),lng: parseFloat(item.longitude)}, marker)
      //       })
      //       markersStation.push(marker)
      //     });
      //     onlineStation.forEach((item, index) => {
      //       //谷歌地图添加图标添加点击事件
      //       const marker = new google.maps.Marker({
      //       	position: new google.maps.LatLng(parseFloat(item.latitude),parseFloat(item.longitude)),
      //       	icon: icon,
      //       	map: this.map,
      //         title: item.stationCode,
      //       });
      //       marker.addListener('click', function(){
      //         that.getPointInfo(marker.getTitle(), {lat: parseFloat(item.latitude),lng: parseFloat(item.longitude)}, marker)
      //       })
      //       markersStation.push(marker)
      //     });
      //     markerCluster.addMarkers(markersStation, true);
      //     this.markersStation = markersStation;
      //   }
      // });
    },
    //here地图添加电动车、换电柜图标和事件
    // setShowData() {
    //   var that = this;
    //   this.status = [
    //     {
    //       number: 0,
    //       img: "https://app.swap.id/file/20212107132138.png",
    //       name: "Online",
    //     },
    //     {
    //       number: 0,
    //       img: "https://app.swap.id/file/20212007132035.png",
    //       name: "Offline",
    //     },
    //     {
    //       number: 0,
    //       img: "https://app.swap.id/file/20212107132108.png",
    //       name: "Online",
    //     },
    //     {
    //       number: 0,
    //       img: "https://app.swap.id/file/20212307132307.png",
    //       name: "Offline",
    //     },
    //   ];

    //   //电动车
    //   getScooterMapData().then((res) => {
    //     if (res.errcode === 200) {
    //       const data = res.data;
    //       this.status[0].number = data.onlineScooterCount;
    //       this.status[1].number = data.offlineScooterCount;
    //       const offlineScooter = data.offlineScooter;
    //       const onlineScooter = data.onlineScooter;
    //       var hicon = new H.map.Icon("https://i.postimg.cc/KvBXBkmB/3-63.png", {
    //         size: { w: 27, h: 49 },
    //       });
    //       offlineScooter.forEach((item, index) => {
    //         if (
    //           item.latitude !== "" &&
    //           item.latitude !== "0" &&
    //           item.longitude !== "" &&
    //           item.longitude !== "0"
    //         ) {
    //           var marker = new H.map.Marker(
    //             {
    //               lat: parseFloat(item.latitude),
    //               lng: parseFloat(item.longitude),
    //             },
    //             {
    //               icon: hicon,
    //               min: 13,
    //               data: {
    //                 scooterCode: item.scooterCode,
    //                 lat: parseFloat(item.latitude),
    //                 lng: parseFloat(item.longitude),
    //               },
    //             }
    //           );
    //           marker.addEventListener("tap", this.onMarkerClick);
    //           this.scooterMarkers.push(marker);
    //           this.hereMap.addObject(marker);
    //         } else {
    //           console.log(item);
    //         }
    //       });
    //       onlineScooter.forEach((item, index) => {
    //         if (
    //           item.latitude !== "" &&
    //           item.latitude !== "0" &&
    //           item.longitude !== "" &&
    //           item.longitude !== "0"
    //         ) {
    //           var marker = new H.map.Marker(
    //             {
    //               lat: parseFloat(item.latitude),
    //               lng: parseFloat(item.longitude),
    //             },
    //             {
    //               icon: hicon,
    //               min: 13,
    //               data: {
    //                 scooterCode: item.scooterCode,
    //                 lat: parseFloat(item.latitude),
    //                 lng: parseFloat(item.longitude),
    //               },
    //             }
    //           );
    //           marker.addEventListener("tap", this.onMarkerClick);
    //           this.scooterMarkers.push(marker);
    //           this.hereMap.addObject(marker);
    //         } else {
    //           console.log(item);
    //         }
    //         // }
    //       });
    //       this.onlineScooterClusteringLayer = this.startClustering(
    //         this.hereMap,
    //         onlineScooter
    //       );
    //       this.offlineScooterClusteringLayer = this.startClustering(
    //         this.hereMap,
    //         offlineScooter
    //       );

    //       this.hereMap.addLayer(this.offlineScooterClusteringLayer);
    //       this.hereMap.addLayer(this.onlineScooterClusteringLayer);
    //     }
    //   });
    //   //换电柜
    //   getStationMapData().then((res) => {
    //     if (res.errcode === 200) {
    //       const data = res.data;
    //       this.status[2].number = data.onlineStationCount;
    //       this.status[3].number = data.offlineStationCount;
    //       const offlineStation = data.offlineStation;
    //       const onlineStation = data.onlineStation;
    //       var hicon = new H.map.Icon("https://i.postimg.cc/kXrzJJW4/3-88.png", {
    //         size: { w: 27, h: 49 },
    //       });
    //       offlineStation.forEach((item, index) => {
    //         if (
    //           item.latitude !== "" &&
    //           item.latitude !== "0" &&
    //           item.longitude !== "" &&
    //           item.longitude !== "0"
    //         ) {
    //           var marker = new H.map.Marker(
    //             {
    //               lat: parseFloat(item.latitude),
    //               lng: parseFloat(item.longitude),
    //             },
    //             {
    //               icon: hicon,
    //               min: 13,
    //             }
    //           );
    //           this.stationMarkers.push(marker);
    //           this.hereMap.addObject(marker);
    //         } else {
    //           console.log(item);
    //         }
    //       });
    //       onlineStation.forEach((item, index) => {
    //         if (
    //           item.latitude != "" &&
    //           item.latitude != "0" &&
    //           item.longitude != "" &&
    //           item.longitude != "0"
    //         ) {
    //           var marker = new H.map.Marker(
    //             {
    //               lat: parseFloat(item.latitude),
    //               lng: parseFloat(item.longitude),
    //             },
    //             {
    //               icon: hicon,
    //               min: 13,
    //             }
    //           );
    //           this.stationMarkers.push(marker);
    //           this.hereMap.addObject(marker);
    //         } else {
    //           console.log(item);
    //         }
    //       });
    //       this.offlineStationClusteringLayer = this.startClustering(
    //         this.hereMap,
    //         offlineStation
    //       );
    //       this.onlineStationClusteringLayer = this.startClustering(
    //         this.hereMap,
    //         onlineStation
    //       );
    //       this.hereMap.addLayer(this.offlineStationClusteringLayer);
    //       this.hereMap.addLayer(this.onlineStationClusteringLayer);
    //       setTimeout(() => {
    //         const legend = document.getElementById("legend");
    //         let WaliedCheetos_CustomUI = this.mapsCenter(this.ui, legend);
    //         WaliedCheetos_CustomUI.addClass("legend");
    //         this.ui.addControl("legend", WaliedCheetos_CustomUI);
    //       }, 1000);
    //     }
    //   });
    // },
    onMarker(index) {
      //获取全屏模式下的下部分选项框div
      let toggle = document.getElementById("toggle-item-active");
      //获取全屏模式下的上部分选项框div
      let showtoggle = document.getElementById("showtoggle");
      switch (index) {
        case 0: {
          //设置搜索框的显示和隐藏
          this.searchShow = 0
          //设置全屏模式下的下部分选项框div的文字
          toggle.innerHTML = this.$t(`centerMap5`);
          //清楚标记
          this.markerCluster.clearMarkers();
          //添加标记
          this.markerCluster.addMarkers(this.markersPointLocation);
          //隐藏全屏模式下的上部分选项框div
          this.onMarker(3);
          //讲选中状态的标记改为未选中
          this.onMarker(4);
          //删除电池标记
          this.onMarker(6);
          break;
        }
        case 1: {
          this.searchShow = 1
          toggle.innerHTML = this.$t(`centerMap7`);
          this.markerCluster.clearMarkers();
          this.markerCluster.addMarkers(this.markersScooter);
          this.onMarker(3);
          this.onMarker(4);
          this.onMarker(6);
          break;
        }
        case 2: {
          this.searchShow = 2
          toggle.innerHTML = this.$t(`centerMap8`);
          this.markerCluster.clearMarkers();
          // this.markerCluster.addMarkers(this.markersStation);
          this.onMarker(3);
          this.onMarker(4);
          this.onMarker(6);
          break;
        }
        case 3: {
          //设置显示隐藏全屏模式下的上部分选项框div
          if (this.show) {
            showtoggle.style.display = "none";
          } else {
            showtoggle.style.display = "block";
          }
          this.show = !this.show;
          break;
        }
        case 4: {
          //改变选中状态的标记
          this.searchText = '';
          if (this.marker && this.marker.getIcon()) {
            // const url = this.marker.getIcon()?  this.marker.getIcon().url : 'https://i.postimg.cc/kXrzJJW4/3-88.png'
            const url = this.marker.getIcon().url
            this.marker.setIcon({
              url: url,
              scaledSize: new google.maps.Size(27, 49),
            })
            this.info = undefined
            this.pointinfo = undefined
            this.marker = undefined;
          } else if (this.marker) {
            this.marker.setMap(null);
            this.info = undefined
            this.pointinfo = undefined
            this.marker = undefined;
          }
          break;
        }
        case 5: {
          //添加电池标记
          this.searchShow = 2
          toggle.innerHTML = this.$t(`centerMap6`);
          this.markerCluster.clearMarkers();
          this.pointBatteryNumList.forEach(v => {
            const marker = new google.maps.Marker({
              position: { lat: parseFloat(v.latitude), lng: parseFloat(v.longitude) },
              map: this.map,
              label: {
                text: String(v.num), // codepoint from https://fonts.google.com/icons
                fontFamily: "Material Icons",
                color: "#ffffff",
                fontSize: "14px",
              },
              icon: {
                url: require('./../../assets/img/yuan.png'),
                scaledSize: new google.maps.Size(30, 30),   //设置图标大小为0，大小可 修改只要url为空即可
              },
              // title: "Material Icon Font Marker",
            });
            this.markerList.push(marker)
          })
          this.onlineScooter.forEach(v => {
            const marker = new google.maps.Marker({
              position: { lat: parseFloat(v.latitude), lng: parseFloat(v.longitude) },
              map: this.map,
              label: {
                text: String(v.num), // codepoint from https://fonts.google.com/icons
                fontFamily: "Material Icons",
                color: "#ffffff",
                fontSize: "14px",
              },
              icon: {
                url: require('./../../assets/img/yuan.png'),
                scaledSize: new google.maps.Size(30, 30),   //设置图标大小为0，大小可 修改只要url为空即可
              },
              // title: "Material Icon Font Marker",
            });
            this.markerList.push(marker)
          })
          this.onMarker(3);
          this.onMarker(4);
          break;
        }
        case 6: {
          //删除电池标记
          if (this.markerList.length > 0) {
            this.markerList.forEach(v => {
              v.setMap(null)
            })
            this.markerList = []
          }
          break;
        }
        default: {

        }
      }
    },
    // onMarker(index) {
    //   let toggle = document.getElementById("toggle-item-active");
    //   let showtoggle = document.getElementById("showtoggle");
    //   switch (index) {
    //     case 0: {
    //       toggle.innerHTML = "只显示站点";
    //       this.hereMap.removeLayer(this.onlineScooterClusteringLayer);
    //       this.hereMap.removeLayer(this.offlineScooterClusteringLayer);
    //       this.hereMap.removeLayer(this.onlineStationClusteringLayer);
    //       this.hereMap.removeLayer(this.offlineStationClusteringLayer);
    //       this.hereMap.addObjects(this.scooterMarkers);
    //       this.hereMap.addObjects(this.stationMarkers);
    //       this.hereMap.removeObjects(this.stationMarkers);
    //       this.hereMap.removeObjects(this.scooterMarkers);
    //       this.onMarker(3);
    //       this.onMarker(4);
    //       break;
    //     }
    //     case 1: {
    //       toggle.innerHTML = "只显示电动车";
    //       this.hereMap.removeLayer(this.onlineScooterClusteringLayer);
    //       this.hereMap.removeLayer(this.offlineScooterClusteringLayer);
    //       this.hereMap.removeLayer(this.onlineStationClusteringLayer);
    //       this.hereMap.removeLayer(this.offlineStationClusteringLayer);
    //       this.hereMap.addLayer(this.onlineScooterClusteringLayer);
    //       this.hereMap.addLayer(this.offlineScooterClusteringLayer);
    //       this.hereMap.addObjects(this.scooterMarkers);
    //       this.hereMap.addObjects(this.stationMarkers);
    //       this.hereMap.removeObjects(this.stationMarkers);
    //       this.hereMap.removeObjects(this.scooterMarkers);
    //       this.hereMap.addObjects(this.scooterMarkers);
    //       this.onMarker(3);
    //       this.onMarker(4);
    //       break;
    //     }
    //     case 2: {
    //       toggle.innerHTML = "只显示换电柜";
    //       this.hereMap.removeLayer(this.onlineScooterClusteringLayer);
    //       this.hereMap.removeLayer(this.offlineScooterClusteringLayer);
    //       this.hereMap.removeLayer(this.onlineStationClusteringLayer);
    //       this.hereMap.removeLayer(this.offlineStationClusteringLayer);
    //       this.hereMap.addLayer(this.onlineStationClusteringLayer);
    //       this.hereMap.addLayer(this.offlineStationClusteringLayer);
    //       this.hereMap.addObjects(this.scooterMarkers);
    //       this.hereMap.addObjects(this.stationMarkers);
    //       this.hereMap.removeObjects(this.stationMarkers);
    //       this.hereMap.removeObjects(this.scooterMarkers);
    //       this.hereMap.addObjects(this.stationMarkers);
    //       this.onMarker(3);
    //       this.onMarker(4);
    //       break;
    //     }
    //     case 3: {
    //       if (this.show) {
    //         showtoggle.style.display = "none";
    //       } else {
    //         showtoggle.style.display = "block";
    //       }
    //       this.show = !this.show;
    //       break;
    //     }
    //     case 4: {
    //       if (this.marker) {
    //         this.hereMap.removeObject(this.marker);
    //         this.marker = undefined;
    //       }
    //       break;
    //     }
    //     default: {
    //     }
    //   }
    // },
    //here地图标记添加事件方法
    // startClustering(map, data) {
    //   // First we need to create an array of DataPoint objects,
    //   // for the ClusterProvider
    //   var dataPoints = data.map(function (item) {
    //     return new H.clustering.DataPoint(
    //       item.latitude,
    //       item.longitude,
    //       null,
    //       item
    //     );
    //   });
    //   // Create a clustering provider with custom options for clusterizing the input
    //   var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
    //     clusteringOptions: {
    //       // Maximum radius of the neighbourhood
    //       // eps: 32,
    //       // minimum weight of points required to form a cluster
    //       minWeight: 2,
    //       // max: 8,
    //     },
    //     max: 12,
    //   });
    //   // var that = this
    //   clusteredDataProvider.addEventListener("tap", this.onMarkerClick);
    //   // clusteredDataProvider.addEventListener('tap', that.onMarkerClick(Event));
    //   // clusteredDataProvider.addEventListener('tap', function(evt, data){
    //   //   that.onMarkerClick(evt)
    //   // });

    //   // Create a layer tha will consume objects from our clustering provider
    //   var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);

    //   // To make objects from clustering provder visible,
    //   // we need to add our layer to the map
    //   // map.addLayer(clusteringLayer);
    //   return clusteringLayer;
    // },
    week(num) {
      let week = "";
      if (num == "1") {
        week = "周一";
        return week;
      } else if (num == "2") {
        week = "周二";
        return week;
      } else if (num == "3") {
        week = "周三";
        return week;
      } else if (num == "4") {
        week = "周四";
        return week;
      } else if (num == "5") {
        week = "周五";
        return week;
      } else if (num == "6") {
        week = "周六";
        return week;
      } else if (num == "7") {
        week = "周日";
        return week;
      }
      return week;
    },
    getPointInfo(pointId, position, marker) {
      this.pointSearchList = []
      this.searchText = ''
      if (!this.zoomToggle) {
        return;
      }
      if (!this.flag) {
        return;
      }
      this.pointinfo = undefined;
      this.info = undefined;
      this.flag = 0
      let that = this;
      if (this.marker && this.marker.getIcon()) {
        // const url = this.marker.getIcon()?  this.marker.getIcon().url : 'https://i.postimg.cc/kXrzJJW4/3-88.png'
        const url = this.marker.getIcon().url
        this.marker.setIcon({
          url: url,
          scaledSize: new google.maps.Size(27, 49),
        })
        this.marker = undefined;
      } else if (this.marker) {
        this.searchText = ''
        this.marker.setMap(null);
        this.marker = undefined;
      }
      if (marker) {
        if (marker.getAnimation() !== null) {
          marker.setAnimation(null);
        } else {
          marker.setAnimation(google.maps.Animation.BOUNCE);
        }
        marker.setIcon({
          url: marker.getIcon().url,
          scaledSize: new google.maps.Size(40.5, 73.5),
        })
        this.marker = marker
      }
      getPointDetail({
        point: pointId,
      }).then((res) => {
        var data = res.data.point;
        position = position || { lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) } || {}
        // position = position || {lat: -6.159204, lng: 106.855414} || {}
        this.map.panTo(position);
        data.imgList = JSON.parse(data.imgList);
        if (this.isJSON(data.timeList)) {
          data.timeList = JSON.parse(data.timeList);
          data.timeList[0].week.forEach((v, index) => {
            data.timeList[0].week[index] = this.week(v)
          })
          data.timeText = data.timeList[0].week[0] + '至' + data.timeList[0].week[data.timeList[0].week.length - 1] + ', ' + data.timeList[0].fixedTime[0] + '~' + data.timeList[0].fixedTime[1]
        } else {
          data.timeText = data.timeList
        }

        that.pointinfo = data;
        if (marker) {
          if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
          }
        } else {
          this.marker = new google.maps.Marker({
            position: position,
            map: this.map,
            animation: google.maps.Animation.DROP,
            scaledSize: new google.maps.Size(40.5, 73.5),
          });
        }
        this.map.setZoom(15)
        this.flag = 1
      })
      // .catch(err=>{
      //   this.flag = 1;
      //   if(marker){
      //     if (marker.getAnimation() !== null) {
      //       marker.setAnimation(null);
      //     }
      //   }
      //   this.marker.setIcon({
      //     url:this.marker.getIcon().url,
      //     scaledSize: new google.maps.Size(27, 49),
      //   })
      //   this.marker = undefined;
      // });
    },
    isJSON(str) {
      if (typeof str == 'string') {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == 'object' && obj) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      }
    },
    getScooterdetailInfo(scooterCode, position, marker) {
      if (!this.zoomToggle) {
        return;
      }
      if (!this.flag) {
        return;
      }
      this.pointinfo = undefined;
      this.info = undefined;
      this.flag = 0
      let that = this;
      if (this.marker && this.marker.getIcon()) {
        // const url = this.marker.getIcon()?  this.marker.getIcon().url : 'https://i.postimg.cc/KvBXBkmB/3-63.png'
        const url = this.marker.getIcon().url
        this.marker.setIcon({
          url: url,
          scaledSize: new google.maps.Size(27, 49),
        })
        this.marker = undefined;
      } else if (this.marker) {
        this.searchText = ''
        this.marker.setMap(null);
        this.marker = undefined;
      }
      if (marker) {
        if (marker.getAnimation() !== null) {
          marker.setAnimation(null);
        } else {
          marker.setAnimation(google.maps.Animation.BOUNCE);
        }
        marker.setIcon({
          url: marker.getIcon().url,
          scaledSize: new google.maps.Size(40.5, 73.5),
        })
        this.marker = marker
      }
      // if (this.logContainer) {
      //   this.logContainer = undefined;
      // }
      getScooterdetail({
        scooterCode: scooterCode,
      }).then((res) => {
        var data = res.data.scooter;
        position = position || { lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) } || {}
        // position = position || {lat: -6.159204, lng: 106.855414} || {}
        // this.geocoder
        //   .geocode({ location: position })
        //   .then((response) => {
        //     if (response.results[0]) {
        this.map.panTo(position);
        //  that.map.setZoom(11);
        // data.address = response.results[0].formatted_address;
        that.info = data;

        if (marker) {
          if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
          }
        } else {
          this.marker = new google.maps.Marker({
            position: position,
            map: this.map,
            animation: google.maps.Animation.DROP,
          });
        }
        this.map.setZoom(15)
        this.flag = 1
      }).catch(err => {
        this.flag = 1;
        if (marker) {
          if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
          }
        }
        this.marker.setIcon({
          url: this.marker.getIcon().url,
          scaledSize: new google.maps.Size(27, 49),
        })
        this.marker = undefined;
      });
    },
    //here地图标记添加的事件
    // onMarkerClick(e) {
    //   if (!this.zoomToggle) {
    //     return;
    //   }
    //   if(!this.flag){
    //     return;
    //   }
    //   this.flag = 0
    //   let that = this;
    //   if (this.marker) {
    //     this.hereMap.removeObject(this.marker);
    //     this.marker = undefined;
    //   }
    //   if(e.target.getData()){
    //     var position = e.target.getGeometry(),
    //     codeData = e.target.getData().a
    //       ? e.target.getData().a.data
    //       : e.target.getData();
    //   }else{
    //     this.flag = 1
    //     return;
    //   }
    //   if (this.logContainer) {
    //     this.logContainer = undefined;
    //   }
    //   if (codeData && codeData.scooterCode) {
    //     getScooterdetail({
    //       scooterCode: codeData.scooterCode,
    //     }).then((res) => {
    //       var data = res.data.scooter;
    //       var geocoder = this.platform.getSearchService(),
    //         reverseGeocodingParameters = {
    //           at: codeData.lat + "," + codeData.lng + ",150", // Berlin
    //           limit: "1",
    //         };
    //       geocoder.reverseGeocode(
    //         reverseGeocodingParameters,
    //         function (result) {
    //           var address = result.items[0].address;
    //           data.address = address.label;
    //           that.info = data;
    //         },
    //         that.onError
    //       );
    //       that.hereMap.setCenter({ lat: codeData.lat, lng: codeData.lng });
    //       this.hereMap.setZoom("15");
    //       this.marker = new H.map.Marker(
    //         { lat: codeData.lat, lng: codeData.lng },
    //         { min: 12 }
    //       );
    //       this.hereMap.addObject(this.marker);
    //       this.flag = 1
    //     }).catch(err=>{
    //       this.flag = 1
    //     });
    //   }
    // },
    // onSuccess(result) {
    //   var address = result.items[0].address;
    //   var address = document.getElementById("address");
    //   address.innerHTML = address.label;
    // },
    // onError() {
    // },
    //返回原来位置
    setCenter() {
      const centerid = document.getElementById("centerid");
      const chicago = { lat: -6.17576, lng: 106.82721 };
      centerid.addEventListener("click", () => {
        this.map.setCenter(chicago);
      });
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        centerid
      );
    },
    //地图 设置地图颜色
    setkongDatas() {
      var that = this
      this.map = new google.maps.Map(
        document.getElementById("kongzhishi-map"),
        {
          center: { lat: -6.17576, lng: 106.82721 },
          zoom: 4,
          styles: [
            {
              "featureType": "administrative",
              "elementType": "geometry",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "transit",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            }
          ],
          // gestureHandling: "greedy",
          // mapTypeId: "roadmap",
          disableDefaultUI: true,
          panControl: false,
          zoomControl: false,
          scrollwheel: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          mapTypeControl: false,
          scaleControl: false,
          streetViewControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          overviewMapControl: false,
          // fullscreenControl: false,
          // mapTypeControl: false,
          // scaleControl: false,
          // mapTypeControl: false,
          // panControl: false,
          // zoomControl: false, // 启用/禁用缩放控件
          // 获取地图元素
        }
      );
      this.map.addListener('click', function () {
        that.searchText = ''
        that.info = undefined;
        that.pointinfo = undefined;
        if (that.marker && that.marker.getIcon()) {
          const url = that.marker.getIcon().url
          that.marker.setIcon({
            url: url,
            scaledSize: new google.maps.Size(27, 49),
          })
          that.marker = undefined;
        } else if (that.marker) {
          that.marker.setMap(null);
          that.marker = undefined;
        }
      })
      // let featureLayer = this.map.getFeatureLayer("LOCALITY");
      // Define a style with purple fill and border.
      //@ts-ignore
      // const featureStyleOptions = {
      //   strokeColor: "#810FCB",
      //   strokeOpacity: 1.0,
      //   strokeWeight: 3.0,
      //   fillColor: "#810FCB",
      //   fillOpacity: 0.5,
      // };
      // // Apply the style to a single boundary.
      // //@ts-ignore
      // featureLayer.style = (options) => {
      //   if (options.feature.placeId == "ChIJ0zQtYiWsVHkRk8lRoB1RNPo") {
      //     // Hana, HI
      //     return featureStyleOptions;
      //   }
      // };
      // this.geocoder = new google.maps.Geocoder();
      this.infowindow = new google.maps.InfoWindow();
      const elementToSendFullscreen = this.map.getDiv().firstChild;
      // 获取全屏按键
      const fullScreenBtn = document.getElementById("fullScreenBtn");
      // 退出全屏按键
      const smallScreenBtn = document.getElementById("smallScreenBtn");
      // 全屏函数
      fullScreenBtn.addEventListener("click", () => {
        if (elementToSendFullscreen.requestFullscreen) {
          elementToSendFullscreen.requestFullscreen();
        } else if (elementToSendFullscreen.webkitRequestFullScreen) {
          elementToSendFullscreen.webkitRequestFullScreen();
        } else if (elementToSendFullscreen.mozRequestFullScreen) {
          elementToSendFullscreen.mozRequestFullScreen();
        } else if (elementToSendFullscreen.msRequestFullScreen) {
          elementToSendFullscreen.msRequestFullScreen();
        }
        fullScreenBtn.style.display = "none";
        smallScreenBtn.style.display = "flex";
        that.zoomToggle = true;
      });
      // 退出全屏函数
      smallScreenBtn.addEventListener("click", () => {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        fullScreenBtn.style.display = "flex";
        smallScreenBtn.style.display = "none";
        that.zoomToggle = false
      });
      // 添加控件集
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
        fullScreenBtn
      );
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
        smallScreenBtn
      );
      this.setPointBatteryNum()
      this.setShowData();
      this.setCenter();
      this.setLeft();
    },
    setPointBatteryNum() {
      getPointBatteryNum().then(res => {
        let list = res.data.list;
        this.pointBatteryNumList = list
      })
    },
    setLeft() {
      const leftLegend = document.getElementById("leftLegend");
      this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
        leftLegend
      );
      const toggleData = document.getElementById("toggleData");
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        toggleData
      );
      const logContainer = document.getElementById("logContainer");
      this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(
        logContainer
      );
    },
    //三个扇形图
    setDatasContent() {
      const key = "options.common.";
      const legendData = [`${key}type1`, `${key}type2`];
      let seriesDataEle = [
        { value: 60, name: `${key}type1` },
        { value: 10, name: `${key}type2` },
      ];
      this.options.elecar = {
        title: {
          text: "电动车在线与离线的比例及数值",
          top: 20,
          x: "center",
          textStyle: {
            color: "#0DB9F2", //颜色
            fontStyle: "normal", //风格
            fontWeight: "normal", //粗细
            fontFamily: "Microsoft yahei", //字体
            fontSize: 14, //大小
            align: "center", //水平对齐
          },
        },
        tooltip: {
          trigger: "item",
          formatter: " {c} ({d}%)",
          // formatter: (params) => {
          // 	// return `${this.$t('common.tipTile')}<br/>
          // 	// 	${this.$t('common.tipName')}:${params.name}`;
          // 	// return `${this.$t('common.tipTile')}<br/>`+
          // 	//  	$('params.name')}:${params.name};
          // }
        },
        legend: {
          orient: "horizontal",
          bottom: "bottom",
          data: legendData,
          textStyle: {
            fontSize: 14,
            color: "#fff",
          },
          formatter: (params) => {
            //${this.$t('common.tipTitle')}
            return this.$t(params);
          },
        },
        label: {
          normal: {
            textStyle: {
              color: "red", // 改变标示文字的颜色
            },
          },
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: seriesDataEle,
            label: {
              normal: {
                formatter: (params) => {
                  return this.$t(params.name);
                },
              },
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      let seriesDataHuan = [
        { value: 90, name: `${key}type1` },
        { value: 30, name: `${key}type2` },
      ];
      this.options.huan = {
        title: {
          text: "换电柜在线与离线的比例及数值",
          top: 20,
          x: "center",
          textStyle: {
            color: "#0DB9F2", //颜色
            fontStyle: "normal", //风格
            fontWeight: "normal", //粗细
            fontFamily: "Microsoft yahei", //字体
            fontSize: 14, //大小
            align: "center", //水平对齐
          },
        },
        tooltip: {
          trigger: "item",
          formatter: " {c} ({d}%)",
        },
        legend: {
          orient: "horizontal",
          bottom: "bottom",
          data: legendData,
          textStyle: {
            fontSize: 14,
            color: "#fff",
          },
          formatter: (params) => {
            return this.$t(params);
          },
        },
        label: {
          normal: {
            textStyle: {
              color: "red", // 改变标示文字的颜色
            },
          },
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: seriesDataEle,
            label: {
              normal: {
                formatter: (params) => {
                  return this.$t(params.name);
                },
              },
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      let seriesDataBattery = [
        { value: 60, name: `${key}type1` },
        { value: 90, name: `${key}type2` },
      ];
      this.options.battery = {
        title: {
          text: "电池在线与离线的比例及数值",
          top: 20,
          x: "center",
          textStyle: {
            color: "#0DB9F2", //颜色
            fontStyle: "normal", //风格
            fontWeight: "normal", //粗细
            fontFamily: "Microsoft yahei", //字体
            fontSize: 14, //大小
            align: "center", //水平对齐
          },
        },
        tooltip: {
          trigger: "item",
          formatter: " {c} ({d}%)",
        },
        legend: {
          orient: "horizontal",
          bottom: "bottom",
          data: legendData,
          textStyle: {
            fontSize: 14,
            color: "#fff",
          },
          formatter: (params) => {
            return this.$t(params);
          },
        },
        label: {
          normal: {
            textStyle: {
              color: "red", // 改变标示文字的颜色
            },
          },
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: seriesDataBattery,
            label: {
              normal: {
                formatter: (params) => {
                  return this.$t(params.name);
                },
              },
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.$echarts
        .init(document.getElementById("content4-item-one"))
        .setOption(this.options.elecar);
      this.$echarts
        .init(document.getElementById("content4-item-two"))
        .setOption(this.options.huan);
      this.$echarts
        .init(document.getElementById("content4-item-three"))
        .setOption(this.options.battery);
    },
    //近七天骑车里程
    setOrders() {
      this.options.orders = {
        tooltip: {
          trigger: "axis",
          axisPointer: { lineStyle: { color: "#fff" } },
        },
        grid: {
          left: "15px",
          top: "38px",
          right: "15px",
          bottom: "0px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            axisLine: { lineStyle: { color: "#57617B" } },
            axisLabel: { textStyle: { color: "#fff" } },
            data: this.WeekConsume.lineUpData,
          },
        ],
        yAxis: [
          {
            name: "km",
            type: "value",
            nameTextStyle: { color: "#fff" },
            axisLine: { lineStyle: { color: "#57617B" } },
            axisLabel: {
              margin: 10,
              textStyle: { color: "#fff" },
              formatter: "{value}",
            },
            splitLine: { lineStyle: { color: "#57617B" } },
          },
        ],
        series: [
          {
            // name: '排队时长',
            type: "line",
            smooth: true,
            lineStyle: { normal: { width: 2 } },
            yAxisIndex: 0,
            areaStyle: {
              normal: {
                color: this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(3, 194, 236, 0.3)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(3, 194, 236, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10,
              },
            },
            itemStyle: { normal: { color: "#03C2EC" } },
            data: this.WeekConsume.dataDateArr,
          },
        ],
      };
      this.$echarts
        .init(document.getElementById("content2-right"))
        .setOption(this.options.orders);
    },
    //24小时换电高峰
    setHuanElement() {
      let that = this;
      let serdata = [];
      getHouersData().then((res) => {
        if (res.errcode === 200) {
          const data = res.data.data;
          data.forEach((item, index) => {
            let md = that.Thousands(item.count);
            serdata.push(item.count);
          });
          this.options.hours = {
            color: "rgba(0, 184, 236, 0.9)",
            // tooltip: {
            // 		trigger: 'axis',
            // 		axisPointer: {
            // 			type: 'cross'
            // 		}
            // 	},
            tooltip: {
              trigger: "axis",
              axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
              },
              backgroundColor: "#fff", //背景色
              padding: [5, 15, 5, 15], //边距6
              borderColor: "#DDDDDF", //边框颜色
              borderWidth: 1, //边框线宽度
              textStyle: {
                //文字样式
                color: "#6A6A6A",
                decoration: "none",
                fontFamily: "Verdana, sans-serif",
              },
              extraCssText: "text-align: left;", //文字对齐方式
              formatter: function (params) {
                //格式化函数
                return (
                  params[0].name +
                  " hour</br>" +
                  that.Thousands(params[0].value)
                );
                //return '现付：'+params[0].data+'</br>'+'月结：'+params[1].data+'</br>'+'平均温度：'+params[2].data
              },
            },
            legend: {
              show: true,
              orient: "left",
              x: "right",
              textStyle: {
                fontSize: "14",
                color: "#fff",
              },
            },
            grid: {
              left: "15px",
              top: "36px",
              right: "15px",
              bottom: "2px",
              containLabel: true,
            },
            textStyle: {
              fontSize: "14",
              fontWeight: "normal",
              color: "#ffffff",
            },
            xAxis: {
              name: "hours",
              name: "单位（万元）",
              nameTextStyle: {
                color: "#aaa",
                nameLocation: "start",
              },
              type: "category",
              boundaryGap: false,
              axisTick: {
                alignWithLabel: true,
                inside: true,
              },
              axisLabel: {
                color: "#fff",
                interval: 0,
                fontSize: 12,
                rotate: 45,
              },
              axisLine: {
                onZero: false,
                lineStyle: {
                  color: "#fff",
                },
              },
              axisPointer: {
                label: {
                  show: false,
                  backgroundColor: "#007EDD",
                },
              },

              data: [
                "0-1",
                "1-2",
                "2-3",
                "3-4",
                "4-5",
                "5-6",
                "6-7",
                "7-8",
                "8-9",
                "9-10",
                "10-11",
                "11-12",
                "12-13",
                "13-14",
                "14-15",
                "15-16",
                "16-17",
                "17-18",
                "18-19",
                "19-20",
                "20-21",
                "21-22",
                "22-23",
                "23-24",
              ],
            },
            yAxis: {
              name: "times",
              nameTextStyle: { color: "#fff" },
              show: true,
              axisLabel: {
                color: "#fff",
                interval: 0,
                fontSize: 12,
              },
              axisTick: {
                alignWithLabel: true,
                inside: true,
              },
              axisLine: {
                onZero: false,
                lineStyle: {
                  color: "#fff",
                },
              },
              axisPointer: {
                label: {
                  show: false,
                },
              },
              type: "value",
              symbol: "circle",
              symbolSize: 10,
              smooth: true,
              splitLine: {
                show: true,
                lineStyle: {
                  // 属性lineStyle（详见lineStyle）控制线条样式
                  color: "rgba(255, 255, 255, 0)",
                },
              },
            },
            series: [
              {
                type: "line",
                //name: '产品或服务产能指数',
                symbol: "circle",
                symbolSize: 10,
                smooth: true,
                itemStyle: {
                  normal: {
                    color: "rgba(0, 184, 236, 0)",
                  },
                  emphasis: {
                    color: "rgba(0, 184, 236, 1)",
                  },
                },
                lineStyle: {
                  normal: {
                    color: "rgba(0, 184, 236, 0.9)",
                  },
                },
                data: serdata,
                areaStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(0, 184, 236, 0.4)",
                        },
                        {
                          offset: 1,
                          color: "rgba(0, 184, 236, 0.1)",
                        },
                      ],
                      globalCoord: false,
                    },
                  },
                },
              },
            ],
          };
          this.$echarts
            .init(document.getElementById("content2-left"))
            .setOption(this.options.hours);
        }
      });
    },
    //近七天骑车车辆
    setQiCheNumberDatas() {
      this.options.cycling = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15px",
          top: "36px",
          right: "15px",
          bottom: "2px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            axisLine: { lineStyle: { color: "#57617B" } },
            axisLabel: { textStyle: { color: "#fff" } },
            data: this.weekConsumeScooter.lineUpData,
          },
        ],
        yAxis: [
          {
            name: "vehicles",
            nameTextStyle: { color: "#fff" },
            type: "value",
            axisLabel: {
              //formatter: '{value} %'
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: "12",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,.1	)",
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,.1)",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.weekConsumeScooter.dataDateArr,
            barWidth: "30%", //柱子宽度
            // barGap: 1, //柱子之间间距
            itemStyle: {
              normal: {
                color: "#2f89cf",
                opacity: 1,
                barBorderRadius: 5,
              },
            },
          },
        ],
      };
      this.$echarts
        .init(document.getElementById("qiche"))
        .setOption(this.options.cycling);
    },
    //近七天骑车订单
    setNumberDatas() {
      const colors = ["#036BC8", "#5EBEFC", "#5EBEFC", "#2EF7F3"];
      this.options.mileage = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        // 	top:20,
        // 	right:5,
        // 	textStyle:{
        // 		color:'white'
        // 	},
        // 	orient:'vertical',
        // 	data:[
        // 			{name:'内存',icon:'circle'},
        // 		]
        // },
        grid: {
          left: "15px",
          top: "38px",
          right: "15px",
          bottom: "12px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          //boundaryGap: false,
          axisTick: { show: false },
          axisLabel: {
            textStyle: {
              color: "white", //刻度颜色
              fontSize: 12, //刻度大小
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0B3148",
              width: 1,
              type: "solid",
            },
          },
          data: this.weekOrder.lineUpData,
        },
        yAxis: {
          name: "orders",
          nameTextStyle: { color: "#fff" },
          type: "value",
          axisTick: { show: false },
          axisLabel: {
            textStyle: {
              color: "white", //刻度颜色
              fontSize: 12, //刻度大小
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0B3148",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            //name:'内存',
            type: "line",
            itemStyle: {
              normal: {
                color: "#006AD4",
              },
              lineStyle: {
                normal: {
                  color: "#F3891B",
                  opacity: 1,
                },
              },
            },
            data: this.weekOrder.dataDateArr,
          },
        ],
      };
      this.$echarts
        .init(document.getElementById("main"))
        .setOption(this.options.mileage);
    },

    async getData() {
    },
    // 定时器
    timer() {
      return setTimeout(() => {
        //this.getData()
      }, 1000);
    },
    //监听   手动触发
    setCanvers(newValue) {
      this.options.elecar.title.text = this.$t("options.elecar"); //'电动车在线与离线的比例及数值'
      this.options.huan.title.text = this.$t("options.huan"); //'电动车在线与离线的比例及数值'
      this.options.battery.title.text = this.$t("options.battery"); //'电动车在线与离线的比例及数值'
      // this.$echarts.init(document.getElementById('content4-item-one')).setOption(this.options.elecar);
      // this.$echarts.init(document.getElementById('content4-item-two')).setOption(this.options.huan);
      // this.$echarts.init(document.getElementById('content4-item-three')).setOption(this.options.battery);
      this.options.mileage.title.text = this.$t("options.elecar"); //'近七天里程'
      this.options.cycling.title.text = this.$t("options.cycling"); //近七天骑行车辆
      this.options.orders.title.text = this.$t("options.orders"); //近七天骑行车辆
      this.$echarts
        .init(document.getElementById("main"))
        .setOption(this.options.mileage);
      this.$echarts
        .init(document.getElementById("qiche"))
        .setOption(this.options.cycling);
      this.$echarts
        .init(document.getElementById("content2-right"))
        .setOption(this.options.orders);

      this.options.hours.title.text = this.$t("options.hoursname"); //'24小时'
      if (newValue == "zh") {
        this.options.hours.xAxis[0].data = [
          "0时",
          "1时",
          "2时",
          "3时",
          "4时",
          "5时",
          "6时",
          "7时",
          "8时",
          "9时",
          "10时",
          "11时",
          "12时",
          "13时",
          "14时",
          "15时",
          "16时",
          "17时",
          "18时",
          "19时",
          "20时",
          "21时",
          "22时",
          "23时",
        ];
      } else {
        this.options.hours.xAxis[0].data = [
          "0hour",
          "1hour",
          "2hour",
          "3时",
          "4时",
          "5时",
          "6时",
          "7时",
          "8时",
          "9时",
          "10时",
          "11时",
          "12时",
          "13时",
          "14时",
          "15时",
          "16时",
          "17时",
          "18时",
          "19时",
          "20时",
          "21时",
          "22时",
          "23时",
        ];
      }
      this.$echarts
        .init(document.getElementById("content2-left"))
        .setOption(this.options.hours);
    },
    mapsCenter(ui, legend) {
      // legend = legend + ''
      legend = legend.innerHTML;
      var inherits = function (childCtor, parentCtor) {
        function tempCtor() { }
        tempCtor.prototype = parentCtor.prototype;
        childCtor.superClass_ = parentCtor.prototype;
        childCtor.prototype = new tempCtor();
        childCtor.prototype.constructor = childCtor;
        childCtor.base = function (me, methodName, var_args) {
          var args = new Array(arguments.length - 2);
          for (var i = 2; arguments.length; i++) {
            args[i - 2] = arguments[i];
          }
          return parentCtor.prototype[methodName].apply(me, args);
        };
      };
      var customUI = function (opt_options) {
        "use strict";
        var options = opt_options || {};
        H.ui.Control.call(this);
        // this.onButtonClick = this.onButtonClick.bind(this);
        // create a button element
        this.increaseBtn_ = new H.ui.base.Button({
          label: legend,
          // onStateChange: this.onButtonClick,
        });
        this.addChild(this.increaseBtn_);
        this.setAlignment(options["alignment"] || "left-bottom");
        this.options_ = options;
      };
      inherits(customUI, H.ui.Control);
      return new customUI();
    },
    mapsFullScreenControl(ui) {
      let that = this;
      var inherits = function (childCtor, parentCtor) {
        function tempCtor() { }
        tempCtor.prototype = parentCtor.prototype;
        childCtor.superClass_ = parentCtor.prototype;
        childCtor.prototype = new tempCtor();
        childCtor.prototype.constructor = childCtor;
        childCtor.base = function (me, methodName, var_args) {
          var args = new Array(arguments.length - 2);
          for (var i = 2; arguments.length; i++) {
            args[i - 2] = arguments[i];
          }
          return parentCtor.prototype[methodName].apply(me, args);
        };
      };

      var customUI = function (opt_options) {
        "use strict";
        var options = opt_options || {};
        H.ui.Control.call(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        // create a button element
        this.increaseBtn_ = new H.ui.base.PushButton({
          label:
            '<img style="margin-top: 4px" src="https://app.swap.id/file/20215910125925.png" alt="">',
          onStateChange: this.onButtonClick,
        });
        //add the buttons as this control's children
        this.addChild(this.increaseBtn_);
        this.setAlignment(options["alignment"] || "top-right");
        this.options_ = options;
      };
      inherits(customUI, H.ui.Control);
      const mapDiv = ui.getMap().getElement();
      let divStyle = mapDiv.style;
      if (mapDiv.runtimeStyle) {
        divStyle = mapDiv.runtimeStyle;
      }
      const originalPos = divStyle.position;
      let originalWidth = divStyle.width;
      let originalHeight = divStyle.height;
      // ie8 hack
      if (originalWidth === "") {
        originalWidth = mapDiv.style.width;
      }
      if (originalHeight === "") {
        originalHeight = mapDiv.style.height;
      }
      const originalTop = divStyle.top;
      const originalLeft = divStyle.left;
      const originalZIndex = divStyle.zIndex;
      let bodyStyle = document.body.style;
      if (document.body.runtimeStyle) {
        bodyStyle = document.body.runtimeStyle;
      }
      const originalOverflow = bodyStyle.overflow;
      customUI.prototype.onButtonClick = function (evt) {
        "use strict";
        if (evt.currentTarget.getState() === "down") {
          that.zoomToggle = true;
          ui.removeControl("legend");
          ui.addControl("zoom", that.zoom);

          const toggleData = document.getElementById("toggleData");
          // const toggleData = document.createElement("div");
          // toggleData.innerHTML =
          //   '<div id="showtoggle"><div class="toggle-item" onclick=onMarker(0)>只显示站点</div>' +
          //   '<div class="toggle-item" onclick=onMarker(1)>只显示电动车</div>' +
          //   '<div class="toggle-item" onclick=onMarker(2)>只显示换电柜</div></div>' +
          //   '<div class="toggle-item toggle-item-active" onclick=onMarker(3)><span id="toggle-item-active">全部</span> <i class="el-icon-arrow-down"></i></div>';
          let toggleData_CustomUI = that.mapsCenter(ui, toggleData);
          toggleData_CustomUI.addClass("toggleData");
          ui.addControl("toggleData", toggleData_CustomUI);
          toggleData_CustomUI.setAlignment("bottom-right");
          // that.zoom.setAlignment('bottom-right');

          mapDiv.style.position = "fixed";
          mapDiv.style.width = "100%";
          mapDiv.style.height = "100%";
          mapDiv.style.top = "0";
          mapDiv.style.left = "0";
          mapDiv.style.zIndex = "100";
          document.body.style.overflow = "hidden";
          ui.getMap().getViewPort().resize();
        } else {
          that.zoomToggle = false;
          const legend = document.getElementById("legend");
          let WaliedCheetos_CustomUI = that.mapsCenter(ui, legend);
          WaliedCheetos_CustomUI.addClass("legend");
          ui.addControl("legend", WaliedCheetos_CustomUI);

          ui.removeControl("zoom");
          ui.removeControl("toggleData");
          if (originalPos === "") {
            mapDiv.style.position = "relative";
          } else {
            mapDiv.style.position = originalPos;
          }
          mapDiv.style.width = originalWidth;
          mapDiv.style.height = originalHeight;
          mapDiv.style.top = originalTop;
          mapDiv.style.left = originalLeft;
          mapDiv.style.zIndex = originalZIndex;
          document.body.style.overflow = originalOverflow;
          ui.getMap().getViewPort().resize();
        }
      };

      var WaliedCheetos_CustomUI = new customUI();
      WaliedCheetos_CustomUI.addClass("customControl");
      ui.addControl("WaliedCheetos_CustomUI", WaliedCheetos_CustomUI);
    },
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  watch: {
    screenfullq(val) {
      this.setNumberDatas(); //近七天里程
      this.setQiCheNumberDatas(); //近七天骑车车辆
      this.setHuanElement(); //24小时换电高峰
      this.setOrders(); //近七天订单();
    },
    dateTimeEleDatas() {
      this.timerone();
    },
    fourData() {
      this.timer();
    },
    typename(val, oldVal) {
    },
    "$i18n.locale"(newValue) {
      // 设置 option参数
      // this.options1.series[0].data.forEach( data => {
      // 	// data.tooltip.formatter = `${this.$t('common.tipTitle')}<br/>
      // 	// 		${this.$t('common.tipName')}: ${data.value}`;

      // })
      // this.options1.series[0].markLine.tooltip.formatter = `${this.$t('common.tipAverage')}</br>
      // 		${this.$t('common.tipContent')}: {c}`;
      // // 使用 setOption()方法重绘
      // //this.myChart.setOption(this.option)
      // if(newValue == 'zh'){
      // 	this.options.elecar.title.text = '电动车在线与离线的比例及数值'
      // }else{
      // 	this.options.elecar.title.text = 'Electric vehicle online and offline'
      // }
      this.setCanvers(newValue);
    },
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  filter: {},
  beforeDestroy() {
    this.closeSse()
  },
};
</script>
<style>
.index {
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 1%;
}
.left {
  width: 25%;
}
.center {
  width: 49%;
  margin: 0 0.1rem;
}
.right {
  width: 25%;
}
.left-item {
  height: 31%;
  width: 100%;
  position: relative;
}
.boxfoot {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.boxfoot:before,.boxfoot:after {
  position: absolute;
  width: 0.06rem;
  height: 0.06rem;
  content: "";
  border-bottom: 2px solid #02a6b5;
  bottom: 0;
}
#fullScreenBtn {
  margin-top: 10px;
  margin-right: 10px;
  background-color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#fullScreenBtn i {
  font-size: 30px;
}
#fullScreenBtn:hover {
  cursor: pointer;
}
#smallScreenBtn {
  margin-top: 10px;
  margin-right: 10px;
  background-color: white;
  height: 40px;
  width: 40px;
  display: none;
  justify-content: center;
  align-items: center;
}
#smallScreenBtn i {
  font-size: 30px;
}
#smallScreenBtn:hover {
  cursor: pointer;
}
.div-title {
  border-radius: 18px;
  width: 100%;
  color: #c5d1e7;
  font-weight: bold;
  font-size: 0.06rem;
  left: 20%;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}
.intro-box {
  display: flex;
  font-size: 0.04rem;
}
.introb {
  display: flex;
  align-items: center;
  margin-left: 0.08rem;
}
.intro-span {
  width: 0.055rem;
  height: 0.05rem;
  margin-right: 0.02rem;
  background: -webkit-linear-gradient(#0dccff, #4760ff);
  background: -o-linear-gradient(#0dccff, #4760ff);
  background: -moz-linear-gradient(#0dccff, #4760ff);
  background: linear-gradient(#0dccff,#4760ff);
}
.intro-orange {
  width: 0.14rem;
  height: 0.005rem;
  background-color: #f28e27;
  position: relative;
}
.intro-dian {
  position: absolute;
  width: 0.044rem;
  height: 0.045rem;
  background-color: #fb6c4a;
  top: -0.02rem;
  left: 0.05rem;
  border-radius: 50%;
}
.left-item.center {
  margin: 3.5% 0;
}
.center-map {
  height: 62%;
  width: 100%;
  border: 1px solid rgba(25, 186, 139, 0.17);
  position: relative;
  margin-top: 0.04rem;
}
.center-maps {
  height: 100%;
  width: 100%;
}
.center-hour {
  width: 100%;
  height: 31%;
  position: relative;
  margin-top: 1.8%;
  display: flex;
}
.center-hour-item {
  width: 50%;
}
.right-top {
  height: 19%;
  width: 100%;
  position: relative;
}
.left_top_main {
  display: flex;
  width: 100%;
  height: 53%;
  margin-top: 5%;
  justify-content: space-around;
}
.middle_toplist_title p {
  font-size: 0.046rem;
  margin-top: 0.02rem;
}
.right-bottom {
  height: 42%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 2.5%;
  margin-bottom: 6%;
}
.item-pie {
  width: 33%;
  display: flex;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.item-pie-title {
  font-size: 0.045rem;
  margin-top: 0.03rem;
  color: #bbc8df;
}
.left_main_circle {
  width: 0.5rem;
  height: 0.5rem;
  background: url(./../../assets/img/cicleBg.png) no-repeat;
  background-size: 100%;
  color: #4adefe;
  text-align: center;
}
.left_main_circles {
  font-size: 0.06rem;
  margin-top: 0.16rem;
}
.left_main_circle:nth-of-type(2) {
  margin: 0 3%;
}
.left_main_circle p {
  margin-top: 30%;
}
.left_main_circle span {
  font-size: 0.6rem;
}
.middle_top {
  width: 100%;
  margin-top: 0.06rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #09254f;
  padding: 0.06rem 0;
}
.middle_toplist {
  text-align: center;
  font-size: 0.05rem;
  color: #fff;
}
.middle_toplist:nth-of-type(2) {
  border-left: 0.01rem solid #4e72b0;
  border-right: 0.01rem solid #4e72b0;
  border-image: -webkit-linear-gradient(#0e1331, #0dccff, #0e1331) 0 30 0;
  border-image: -moz-linear-gradient(#0e1331, #0dccff, #0e1331) 0 30 0;
  border-image: linear-gradient(#0e1331, #0dccff, #0e1331) 0 30 0;
  padding: 0 5px;
}
.list_toplist_num {
  margin: 0.04rem 0;
  font-size: 0.08rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.color_mi {
  background-image: -webkit-linear-gradient(bottom,
      rgb(1, 94, 234),
      rgb(0, 192, 250));
}
.color_order {
  background-image: -webkit-linear-gradient(bottom,
      rgb(45, 214, 96),
      rgb(89, 242, 204));
}
.color_user {
  background-image: -webkit-linear-gradient(bottom,
      rgb(253, 100, 79),
      rgb(242, 142, 38));
}
.today_toplist_font {
  font-size: 0.05rem;
}
.today_toplist_font span {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.report {
  display: flex;
  justify-content: center;
}
.report1 {
  width: 10rem;
  height: 6rem;
  text-align: center;
  padding-top: 2rem;
  background: url(./../../assets/img/report01.png) center no-repeat;
  text-align: center;
  padding-top: 3rem;
}
.report2 {
  width: 10rem;
  height: 6rem;
  text-align: center;
  padding-top: 2rem;
  background: url(./../../assets/img/report02.png) center no-repeat;
  text-align: center;
  padding-top: 3rem;
}
.report p {
  font-weight: bold;
  font-size: 18px;
  color: #ff0000;
}
.report small {
  font-size: 24px;
}
.right-bottom2 {
  height: 31%;
  width: 100%;
  position: relative;
  margin-top: 1.8%;
}
.con_items {
  display: flex;
  margin-top: 0.06rem;
  align-items: center;
  justify-content: space-between;
}
.con_item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 0.04rem;
  background-color: rgba(0, 184, 236, 0.1);
  width: 30%;
  height: 0.5rem;
}
.con_item_icon {
  width: 0.1rem;
  height: 0.1rem;
  margin-right: 0.02rem;
}
.con_item_icon img {
  width: 100%;
  height: 100%;
}
.number-all {
  padding: 0.03rem 0;
  font-size: 0.08rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.font_clip_color {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#centerid {
  padding: 0.01rem;
  margin-right: 0.05rem;
  background-color: rgb(255, 255, 255);
}
.hhb-one {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 76%;
}
.hhb-onet {
  border: 1px solid;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hhb-one-numberall {
  font-size: 150%;
}
.sycm ul {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding: 0.16rem 0;
}
.sycm li {
  float: left;
  width: 33.33%;
  text-align: center;
  position: relative;
}
.sycm li:before {
  position: absolute;
  content: "";
  height: 30%;
  width: 1px;
  background: rgba(255, 255, 255, 0.1);
  right: 0;
  top: 15%;
}
.sycm li:last-child:before {
  width: 0;
}
.sycm li h2 {
  font-size: 0.3rem;
  color: #c5ccff;
}
.sycm li span {
  font-size: 0.18rem;
  color: #fff;
  opacity: 0.5;
}
.notice {
  display: flex;
  justify-content: space-around;
  font-size: 0.06rem;
}
.notice-item {
  padding: 0.03rem;
  border: 0.01rem solid rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.4);
}
.intro {
  display: flex;
  width: 100%;
  font-size: 0.012rem;
  flex-wrap: wrap;
}
.pie_intro {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.04rem;
}
.pie_size {
  width: 0.07rem;
  height: 0.07rem;
  background-color: red;
  margin-left: 0.02rem;
}
.content_number {
  display: flex;
  align-items: center;
}
.bgc_W {
  background: -webkit-linear-gradient(#00c0fa, #015eea);
  background: -o-linear-gradient(#00c0fa, #015eea);
  background: -moz-linear-gradient(#00c0fa, #015eea);
  background: linear-gradient(#00c0fa, #015eea);
}
.bgc_O {
  background: -webkit-linear-gradient(#59f2cc, #2dd660);
  background: -o-linear-gradient(#59f2cc, #2dd660);
  background: -moz-linear-gradient(#59f2cc, #2dd660);
  background: linear-gradient(#59f2cc, #2dd660);
}
.bgc_M {
  background: -webkit-linear-gradient(#f28e26, #fd644f);
  background: -o-linear-gradient(#f28e26, #fd644f);
  background: -moz-linear-gradient(#f28e26, #fd644f);
  background: linear-gradient(#f28e26, #fd644f);
}
.bgc_F {
  background: -webkit-linear-gradient(#7776ff, #ae2cf1);
  background: -o-linear-gradient(#7776ff, #ae2cf1);
  background: -moz-linear-gradient(#7776ff, #ae2cf1);
  background: linear-gradient(#7776ff, #ae2cf1);
}
.bgc_R {
  background: -webkit-linear-gradient(#b6359c, #ef0a6a);
  background: -o-linear-gradient(#b6359c, #ef0a6a);
  background: -moz-linear-gradient(#b6359c, #ef0a6a);
  background: linear-gradient(#b6359c, #ef0a6a);
}
.line_x {
  margin-top: 0.06rem;
  margin-bottom: 0.02rem;
  width: 100%;
  height: 2px;
}
.item_pro {
  font-size: 0.04rem;
  width: 18%;
  height: 100%;
  text-align: center;
  padding: 0.1rem;
  background-color: #0e3169;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.item_pro_name {
  color: #9bacca;
  width: 0.6rem;
}
.font_color {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 0.08rem;
}
.item_img {
  width: 0.2rem;
  height: 0.21rem;
  margin: 0.04rem 0;
}
.item_img img {
  width: 100%;
  height: 100%;
}
.customControl {
  text-align: center;
}
.legend {
  font-family: Arial, sans-serif;
  background: #ffffff;
  margin: 10px;
  font-size: 0.02rem;
}
.legend h3 {
  margin-top: 0;
  margin-bottom: 10px;
}
.legend img {
  vertical-align: middle;
  width: 0.1rem;
  margin: 0.02rem 0;
}
.legend .H_el {
  height: 100%;
  width: 236px;
  background: #ffffff;
  box-shadow: 0em 0 0.4em 0 rgb(15 22 33 / 0%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.log {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 285px;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
}
.log-entry {
  padding: 5px;
  border-bottom: 1px solid #d0d9e9;
}
.log-entry:nth-child(odd) {
  background-color: #e1e7f1;
}
.legend-item {
  flex: 1;
  display: flex;
  padding: 16px 0 5px 0;
  justify-content: center;
  align-items: center;
}
.legend-item img {
  width: 46px;
  height: 46px;
}
.legend-item div {
  display: flex;
  flex-direction: column;
}
.legend-item div :nth-child(1) {
  font-size: 12px;
  color: #000;
}
.legend-item div :nth-child(2) {
  /* width: 70px; */
}
.legend-item0 div :nth-child(2) {
  cursor: pointer;
  background-image: -webkit-linear-gradient(bottom, #015eea, #00c0fa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  margin-top: 6px;
  min-width: 35px;
}
.legend-item1 div :nth-child(2) {
  cursor: pointer;
  background-image: -webkit-linear-gradient(bottom, #f3e459, #ffa900);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  margin-top: 6px;
}
.legend-item2 div :nth-child(2) {
  cursor: pointer;
  background-image: -webkit-linear-gradient(bottom, #40edc2, #07c641);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  margin-top: 6px;
}
.legend-item3 div :nth-child(2) {
  cursor: pointer;
  background-image: -webkit-linear-gradient(bottom, #f28e26, #fd644f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  margin-top: 6px;
}
.legend-item0 {
  border-bottom: 1px solid #f2f2f2;
}
.legend-item0-act {
  background: linear-gradient(to left, #FFFFFF 0%, #C0D9FE 100%);
  border-left: 3px solid #006CEC;
}
.legend-item1 {
  border-bottom: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2;
}
.legend-item1-act {
  background: linear-gradient(to left, #FFFFFF 0%, #FFEDC8 100%);
  border-left: 3px solid #FEAF0A;
}
.legend-item2-act {
  background: linear-gradient(to left, #FFFFFF 0%, #C9FFDA 100%);
  border-left: 3px solid #3ED36C;
}
.legend-item3 {
  border-left: 1px solid #f2f2f2;
}
.legend-item3-act {
  background: linear-gradient(to left, #FFFFFF 0%, #FFCCC2 100%);
  border-left: 3px solid #F36348;
}
.toggleData {
  font-family: Arial, sans-serif;
  background: #ffffff;
  margin: 10px;
  font-size: 0.02rem;
}
.toggleData h3 {
  margin-top: 0;
  margin-bottom: 10px;
}
.toggleData img {
  vertical-align: middle;
  width: 0.1rem;
  margin: 0.02rem 0;
}
.toggleData .H_el {
  /* widows: 100% !important; */
  height: 100%;
  width: 140px;
  background: #ffffff;
  box-shadow: 0em 0 0.4em 0 rgb(15 22 33 / 0%);
}
.toggle-item {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 8px 0 8px;
}
.toggle-item-active {
  background: #0563eb;
  color: white;
}
.info-personal {
  display: flex;
  padding: 15px 20px;
  /* justify-content: center; */
  align-items: center;
}
.info-personal img {
  width: 52px;
  height: 52px;
  border-radius: 52px;
}
.info-personal div {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.info-personal div :nth-child(1) {
  color: #333333;
  font-weight: 700;
  font-size: 14px;
}
.info-personal div :nth-child(2) {
  color: #333333;
  font-size: 10px;
}
.zaixian {
  color: white;
  background-color: #2dd660;
  font-size: 10px;
  width: 40px;
  text-align: center;
}
.lixian {
  color: white;
  background-color: red;
  font-size: 10px;
  width: 40px;
  text-align: center;
}
.info-motorcycle {
  display: flex;
  justify-content: space-between;
  margin: 15px 20px 0 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  color: #333333;
}
.info-motorcycle span {
  width: 150px;
  text-align: right;
}
.info-motorcycle img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.info-motorcycle div:nth-child(1) {
  display: flex;
  align-content: center;
}
.search {
  position: absolute;
  top: -5px;
  left: 35px;
  background: none !important;
  margin: 0;
  font-size: 12px;
  z-index: 200;
}
.info-personal div :nth-child(3) {
  color: white;
  background-color: #2dd660;
  font-size: 10px;
  width: 40px;
  text-align: center;
}
.info-motorcycle {
  display: flex;
  margin: 10px 10px 0 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  color: #333333;
}
.info-motorcycle img {
  width: 15px;
  height: 15px;
}
.data {
  top: 35px !important;
  left: 35px !important;
  margin: 0;
  padding: 0;
  font-size: 12px;
  z-index: 200;
}
#centerid img {
  /* margin-top: 6px; */
}
#centerid {
  width: 40px;
  height: 40px;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}
#leftLegend {
  display: block;
}
:-webkit-full-screen #leftLegend {
  display: none !important;
}
#centerid {
  display: flex;
}
:-webkit-full-screen #centerid {
  display: none !important;
}
#toggleData {
  display: none;
}
:-webkit-full-screen #toggleData {
  display: block !important;
  bottom: 14px !important;
}
.search .el-button {
  border-radius: 0 !important;
  padding: 12px 16px;
}
.search .el-input__inner {
  border-radius: 0;
}
.search-list {
  background: #fff;
  max-height: 30em;
  overflow: auto;
}
.search-list p {
  align-items: center;
  border-top: 1px solid #f1f3f3;
  cursor: pointer;
  display: flex;
  height: 3em;
  padding: 0 16px;
}
.search-list::-webkit-scrollbar {
  display: none;
}
</style>
