<template>
  <div id="main" style="width: 100%; height: 90%"></div>
</template>
<script>
import {
  getMothScooterAndCount,//近6月日活跃车辆/日换电车辆
} from "../../../request/conlog.js";
export default {
  name: "seventActive", //活跃
  data() {
    return {
      options: {
        mileage: null,
      },
      weekOrder: {
        items: [],
        scooters: [],
        counts: [],
      },
    };
  },
  mounted() {
    getMothScooterAndCount().then(res=>{
      if(res.errcode === 200){
        let math = require('mathjs');  
        const datas = res.data.list;
        for (let i = datas.length - 1; i > -1; i--) {
          datas[i].changeScooter = math.random() * 900000
          datas[i].activiteScooter = math.random() * 8100000
          this.weekOrder.items.push(datas[i].createTime);
          this.weekOrder.counts.push(datas[i].changeScooter);
          this.weekOrder.scooters.push(datas[i].activiteScooter);
        }
        this.setNumberDatas();
      }
    })
  },
  methods: {
    //近七天骑车订单
    setNumberDatas() {
      const colors = ["#036BC8", "#5EBEFC", "#5EBEFC", "#2EF7F3"];
      const activeVehicles = this.$t(`activeVehicles`);
      const swapVehicles = this.$t(`swapVehicles`);
      this.options.mileage = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15px",
          top: "38px",
          right: "15px",
          bottom: "12px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          //boundaryGap: false,//是否在边开始
          axisTick: { show: false },
          axisLabel: {
            //x轴  字体
            textStyle: {
              color: "#72747d", //刻度颜色
              fontSize: 12, //刻度大小
            },
          },

          axisLine: {
            //x轴设置
            show: true, //x轴是否显示
            lineStyle: {
              color: "#ffffff",
              width: 0.2,
              type: "solid",
            },
          },
          data: this.weekOrder.items,
        },
        yAxis: {
          name: "Vehicles",
          nameTextStyle: { color: "#75829e" },
          type: "value",
          axisTick: { show: false },
          axisLabel: {
            //y轴文字
            textStyle: {
              color: "#72747d", //刻度颜色
              fontSize: 12, //刻度大小
            },
          },
          axisLine: {
            show: false, //y轴设置
            lineStyle: {
              color: "#000",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            //x轴横线
            show: true, //,
            lineStyle: {
                color: "rgba(255,255,255,.1)",
              },
          },
        },
        series: [
          {
            name: this.$t(`swapVehicles`),
            type: "bar",
            data: this.weekOrder.counts,
            barWidth: "20%", //柱子宽度
            barGap: 0, //柱子之间间距
            itemStyle: {
              normal: {

              },
            },
          },
          {
            name: this.$t(`activeVehicles`),
            type: "bar",
            data: this.weekOrder.scooters,
            barWidth: "20%", //柱子宽度
            barGap: 0, //柱子之间间距
            itemStyle: {
              normal: {

              },
            },
          },
        ],
        legend: {
          data: [activeVehicles, swapVehicles],
          selected: {
            activeVehicles: true,
            swapVehicles: true,
          },
          textStyle: {
            color: "#c4d1e8",
          },
          right: 60,
        },
      };
      this.$echarts
        .init(document.getElementById("main"))
        .setOption(this.options.mileage);
    },
  },
};
</script>