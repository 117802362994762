<template>
  <div>
    <div :id="id" style="width: 0.5rem; height: 0.5rem"></div>
    <!-- <p style="font-size: 12px">{{percentageInfo}}</p> -->
  </div>
</template>

<script>
const resizeChartMethod = "$__resizeChartMethod";

export default {
  data() {
    return {
      chart: null,
    };
  },

  created() {},
  beforeDestroy() {},
  mounted() {
    this.draw();
    // console.log(this.percentageInfo)
  },
  props: {
    percentageInfo: {
      type: Object,
      default: {}
    },
    number: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      required: true,
      default: "chartRate",
    },
    // tips: {
    //   type: Number,
    //   required: true,
    //   default: 50
    // },
    // all:{
    //   type: Number,
    //   required: true,
    //   default: 100
    // },
    colorObj: {
      type: Object,
      default: function () {
        return {
          textStyle: "#3fc0fb",
          series: {
            color: ["#00bcd44a", "transparent"],
            dataColor: {
              normal: "#03a9f4",
              shadowColor: "#97e2f5",
            },
          },
        };
      },
    },
  },
  methods: {
    // GetPercent(num, total) {
    //   /// <summary>
    //   /// 求百分比
    //   /// </summary>
    //   /// <param name="num">当前数</param>
    //   /// <param name="total">总数</param>
    //   num = parseFloat(num);
    //   total = parseFloat(total);
    //   if (isNaN(num) || isNaN(total)) {
    //     return "-";
    //   }
    //   return total <= 0 ? "0" : Math.round((num / total) * 10000) / 100.0;
    // },
    /** * 线性渐变起止方向的计算方法 * * * @param {*} startArc 开始角度 * @param {*} endArc 结束角度 * @returns 四个坐标 x,y,x2,y2 */
    // getCoordinates(startArc, endArc) {
    //   const posi = [
    //     Math.sin(startArc),
    //     -Math.cos(startArc),
    //     Math.sin(endArc),
    //     -Math.cos(endArc),
    //   ];
    //   const dx = posi[2] - posi[0];
    //   const dy = posi[3] - posi[1];
    //   return this.getLocation(dx, dy);
    // },

    // getLocation(dx, dy) {
    //   const tanV = dx / dy;
    //   const directSign = Math.abs(tanV) < 1;
    //   const t = directSign ? tanV : 1 / tanV;
    //   const sign1 = t > 0 ? 1 : -1;
    //   const sign2 = dx > 0 ? 1 : -1;
    //   const sign = directSign ? sign1 * sign2 : sign2;
    //   const group1 = [0.5 - (sign * t) / 2, 0.5 + (sign * t) / 2];
    //   const group2 = sign > 0 ? [0, 1] : [1, 0];
    //   const group = [...group1, ...group2];
    //   const keys = directSign ? ["x", "x2", "y", "y2"] : ["y", "y2", "x", "x2"];
    //   let res = {};
    //   keys.forEach((k, idx) => {
    //     res[k] = group[idx];
    //   });
    //   return res;
    // },
    // dataMd() {
    //   let data = [
    //     {
    //       value: 135,
    //       name: "Operations(IDN)",
    //       itemStyle: {
    //         color: {
    //           // type:'liner',
    //           // x: lineDretion.x,
    //           // y: lineDretion.y,
    //           // x2:  lineDretion.x2,
    //           // y2:  lineDretion.y2,
    //           colorStops: [
    //             // !! 在此添加想要的渐变过程色 !!
    //             { offset: 0, color: "#59F2CC" },
    //             { offset: 1, color: "#2DD660" },
    //           ],
    //         },
    //       },
    //     },
    //     {
    //       value: 335,
    //       name: "Maintenance(IDN)",
    //       itemStyle: {
    //         color: {
    //           type: "liner",
    //           //  x: lineDretion2.x,
    //           //   y: lineDretion2.y,
    //           //   x2:  lineDretion2.x2,
    //           //   y2:  lineDretion2.y2,
    //           // x: 1,
    //           // y: 0.2113248654051872,
    //           // x2: 1,
    //           // y2: 0.7886751345948129,

    //           colorStops: [
    //             { offset: 0, color: "#015EEA" },
    //             { offset: 1, color: "#00C0FA" },
    //           ],
    //         },
    //       },
    //     },
    //   ];
    //   // const lineDretion = this.getCoordinates(d2,d);//335
    //   // const lineDretion2 = this.getCoordinates(d,d2);//135
    //   // const d = this.GetPercent(135,135+335);
    //   // const d2 = this.GetPercent(335,135+335);
    //   // console.log('12132321',this.getCoordinates(d,d2));
    //   //   color: [this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [{
    //   //     offset: 0,
    //   //     color: '#015EEA'
    //   // },
    //   // {
    //   //     offset: 0.9,
    //   //     color: '#00C0FA'
    //   // }]), this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [{
    //   //     offset: 0,
    //   //     color: '#59F2CC'
    //   // },
    //   // {
    //   //     offset: 0.9,
    //   //     color: '#2DD660'
    //   // }]),this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [{
    //   //     offset: 0,
    //   //     color: '#F28E26'
    //   // },
    //   // {
    //   //     offset: 0.9,
    //   //     color: '#FD644F'
    //   // }]), this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [{
    //   //     offset: 0,
    //   //     color: '#7776FF'
    //   // },
    //   // {
    //   //     offset: 0.9,
    //   //     color: '#AE2CF1'
    //   // }]),
    //   // this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [{
    //   //     offset: 0,
    //   //     color: '#B6359C'
    //   // },
    //   // {
    //   //     offset: 0.9,
    //   //     color: '#EF0A6A'
    //   // }])
    //   // ],
    // },

    setColor(index) {
      let colors = [
        this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [
          {
            offset: 0,
            color: "#00C0FA",
          },
          {
            offset: 0.9,
            color: "#015EEA",
          },
        ]),
        this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [
          {
            offset: 0,
            color: "#59F2CC",
          },
          {
            offset: 0.9,
            color: "#2DD660",
          },
        ]),
        this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [
          {
            offset: 0,
            color: "#F28E26",
          },
          {
            offset: 0.9,
            color: "#FD644F",
          },
        ]),
        this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [
          {
            offset: 0,
            color: "#7776FF",
          },
          {
            offset: 0.9,
            color: "#AE2CF1",
          },
        ]),
        this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [
          {
            offset: 0,
            color: "#B6359C",
          },
          {
            offset: 0.9,
            color: "#EF0A6A",
          },
        ]),
      ];
      let pei_colors = [];
      // console.log("index", index);
      for (let i = 0; i < index; i++) {
        // console.log("i", i);
        pei_colors.push(colors[i]);
      }
      return pei_colors;
    },
    draw() {
      console.log(this.percentageInfo)
      let data = [
        {
          value: this.percentageInfo.indonesianWarehouse,
          name: this.$t(`inventoryRatioInfo.Warehouse`),
          itemStyle: { color: "#0699f6" },
          value1: this.percentageInfo.warehouseTotal
        },
        {
          value: this.percentageInfo.indonesianOperations,
          name: this.$t(`inventoryRatioInfo.Operation`),
          itemStyle: { color: "#44d7b4" },
          value1: this.percentageInfo.operationsTotal
        },
        {
          value: this.percentageInfo.indonesianMaintenance,
          name: this.$t(`inventoryRatioInfo.Maintenance`),
          itemStyle: { color: "#dc7063" },
          value1: this.percentageInfo.maintenanceTotal
        },
        // {
        //   value: this.percentageInfo.chineseFactory,
        //   name: this.$t(`inventoryRatioInfo.Factory`),
        //   itemStyle: { color: "#865df9" },
        // },
        // {
        //   value: this.percentageInfo['chinaR&D'],
        //   name: this.$t(`inventoryRatioInfo.RD`),
        //   itemStyle: { color: "#bd3493" },
        // },
      ];

      // console.log(d,d2);
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById(this.id));
      let that = this;
      // console.log("pei_colors", this.setColor(2));
      let option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            //格式化函数
            console.log(params)
            return (
              params.marker +
              "设备数量" + "&nbsp;&nbsp;&nbsp;" + 
              params.data.value1 +
              "</br>" + 
              params.marker +
              params.name +
              // "</br>" +
              // that.$t(`todayA`) +
              "&nbsp;&nbsp;&nbsp;" + 
              params.data.value + '%'
              
              // that.$t(`totalA`) +
              // ": " +
              // that.Thousands(params[1].data) +
              // "</br>" +
              // that.$t(`todayB`) +
              // ": " +
              // that.Thousands(params[2].data) +
              // "</br>" +
              // that.$t(`totalB`) +
              // ": " +
              // that.Thousands(params[3].data)
            );
          },
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "90%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: data,
          },
        ],
      };
      this.chart.setOption(option);

      //  let option = {
      //           tooltip: {
      //               trigger: 'item',
      //               formatter: function (params) {   //格式化函数
      //                    // console.log('paramsss',params)
      //                     return params.name+'</br>'+params.value+'('+params.percent.toFixed(0)+"%)"
      //                     //return '现付：'+params[0].data+'</br>'+'月结：'+params[1].data+'</br>'+'平均温度：'+params[2].data
      //                   },

      //           },

      //           series: [
      //           {
      //               //name:'访问来源',
      //               type:'pie',

      //               selectedMode: 'single',
      //               radius: [0, '92%'],
      //               label: {
      //                 normal: {
      //                   position: 'inner',
      //                   fontSize:'7px',
      //                   formatter: function (params) {   //格式化函数
      //                     //console.log('paramsss',params)
      //                     return params.name+'\n'+params.percent.toFixed(0)+"%"
      //                     //return '现付：'+params[0].data+'</br>'+'月结：'+params[1].data+'</br>'+'平均温度：'+params[2].data
      //                   }
      //                 //formatter: "{b}({d}%)"
      //               }

      //               },
      //               // emphasis: {
      //               //     label: {
      //               //         show: true,
      //               //         fontSize: '4',
      //               //         //fontWeight: 'bold'
      //               //     }
      //               // },
      //               labelLine: {
      //                   normal: {show: false}
      //               },
      //               data:data

      //           },
      //       ]

      //    }
      // let option = {
      //       tooltip: {
      //                     trigger: 'item',
      //                     formatter: "{b}: {c} ({d}%)"
      //       },
      //       series: [
      //         {

      //           type: 'pie',
      //           radius: '20%',
      //           avoidLabelOverlap: false,
      //           label: {
      //             normal: {

      //               formatter: "{b}:{d}% \n ({c})"
      //             },

      //           },

      //           data: [
      //             { value: 1048, name: 'Search Engine' },
      //             { value: 735, name: 'Direct' },
      //             { value: 580, name: 'Email' },
      //             { value: 484, name: 'Union Ads' },
      //             { value: 300, name: 'Video Ads' }
      //           ],

      //         }
      //       ]
      //     };
    },
  },
  destroyed() {
    window.onresize = null;
  },
};
</script>

<style lang="scss" scoped>
</style>