<template>
  <div>
    <div :id="id" style="width:0.5rem;height:0.5rem;"></div>
  </div>
</template>
<script>
const resizeChartMethod = '$__resizeChartMethod';
export default {
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.draw();
  },
  props: {
    id: {
      type: String,
      required: true,
      default: "chartRate"
    },
    tips: {
      type: Number,
      required: true,
      default: 50
    },
    all:{
      type: Number,
      required: true,
      default: 100
    },
    colorObj: {
      type: Object,
      default: function() {
        return {
          textStyle: "#3fc0fb",
          series: {
            color: ["#00bcd44a", "transparent"],
            dataColor: {
              normal: "#03a9f4",
              shadowColor: "#97e2f5"
            }
          }
        };
      }
    }
  },
  methods: {
    GetPercent(num, total) {
				/// <summary>
				/// 求百分比
				/// </summary>
				/// <param name="num">当前数</param>
				/// <param name="total">总数</param>
				num = parseFloat(num);
				total = parseFloat(total);
				if (isNaN(num) || isNaN(total)) {
					return "-";
				}
				return total <= 0 ? "0" : (Math.round(num / total * 10000) / 100.00);
		},
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById(this.id));
      //  ----------------------------------------------------------------
      console.log(this.tips,this.all)
      let tips = this.GetPercent(this.tips,this.all);
      let option = {
        title: [
          {
            text: tips * 1 + "%",
            x: "center",
            y: "center",
            textStyle: {
              color: this.colorObj.textStyle,
              fontSize: 14
            }
          }
        ],
        series: [
            {
            type: "pie",
            radius: ["80%", "85%"],
            center: ["50%", "50%"],
            
            color: this.colorObj.series.color,
            label: {
              normal: {
                show: false
              }
            },

            data: [
              {
                value: this.tips,
                itemStyle: {
                  normal: {
                    color: this.colorObj.series.dataColor.normal,
                    shadowBlur: 10,
                    shadowColor: this.colorObj.series.dataColor.shadowColor
                  }
                }
              },
              {
                value: this.all - this.tips
              }
            ]
          }
        ]
      };
      this.chart.setOption(option);
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>