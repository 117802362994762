<template>
  <div id="qiche" style="width: 100%; height: 86%"></div>
</template>
<script>
import {
  getMothScooterAndConsume,
} from "../../../request/conlog.js";
export default {
  name: "seventActive", //活跃/里程
  data() {
    return {
      options: {
        cycling: null,
      },
      weekConsumeScooter: {
        recharges: [],
        mileages: [],
        items: [],
      },
    };
  },
  mounted() {
    let _that = this;
    getMothScooterAndConsume().then(res=>{
     
      let math = require('mathjs');  
      const datas = res.data.list;
      for (let i = datas.length - 1; i > -1; i--) {
        datas[i].rechargeValue = math.random() * 1500000
        datas[i].consumeValue = math.random() * 1000000
        this.weekConsumeScooter.recharges.push(datas[i].rechargeValue);
        this.weekConsumeScooter.mileages.push(datas[i].consumeValue);
        this.weekConsumeScooter.items.push(datas[i].createTime);
      };
      _that.setQiCheNumberDatasTwo();
    })
  },
  methods: {
    setQiCheNumberDatasTwo() {
      const topup = this.$t(`topup`);
      const expense = this.$t(`expense`);
      console.log(
        "this.weekConsumeScooter.活跃、里程",
        this.weekConsumeScooter
      );
      this.options.cycling = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15px",
          top: "36px",
          right: "15px",
          bottom: "2px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,//是否在边开始
            axisTick: { show: false },
            axisLabel: {
              //x轴  字体
              textStyle: {
                color: "#72747d", //刻度颜色
                fontSize: 12, //刻度大小
              },
            },
            axisLine: {//x轴设置
              show: true, //x轴是否显示
              lineStyle: {
                color: "#fff",
                width: 0.2,
                type: "solid",
              },
            },
            data: this.weekConsumeScooter.items,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "km",
            // nameLocation: "start",
            // min: 0,
            // max: 250,
            position: "left",
            nameTextStyle: { 
              color: "#75829e",
              align: "right",
              padding: [0, 35, 0, 0]
            },
            offset: 0,
            axisLabel: {
              //formatter: '{value} %'
              // show: true,
              textStyle: {
                color: "#72747d",
                fontSize: "12",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              //x轴设置
              show: false, //x轴是否显示
              lineStyle: {
                color: "#ffffff",
                width: 0.2,
                type: "solid",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,.1)",
              },
            },
          },
        ],
        series: [
          {
            name: this.$t(`expense`),
            type: "line",
            data: this.weekConsumeScooter.mileages,
            barWidth: "20%", //柱子宽度
            // barGap: 1, //柱子之间间距
            itemStyle: {
              normal: {
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            name: this.$t(`topup`),
            type: "line",

            itemStyle: {
              normal: {
                color: "#FB6C4A", //折线点颜色
                lineStyle: {
                  color: "#F28E27", //折线颜色
                },
              },
            },
            // yAxisIndex: 1,
            // data: [10, 23, 69, 63, 522, 10, 90],
            data: this.weekConsumeScooter.recharges,
          },
        ],
        legend: {
          data: [expense, topup],
          selected: {
            // vehicles: true,
            expense: true,
            topup: true,
          },
          // orient: 'vertical',
          right: 60,
          // top: 'center'
          // backgroundColor: '#ccc',
          textStyle: {
            color: "#c4d1e8",
          },
        },
      };
      this.$echarts
        .init(document.getElementById("qiche"))
        .setOption(this.options.cycling);
    },
  },
};
</script>