<template>
  <div id="recordMoth" style="width: 100%; height: 86%"></div>
</template>
<script>
import {
  getMothRecord,
} from "../../../request/conlog.js";
export default {
  name: "mothRecord", //活跃/里程
  data() {
    return {
      options: {
        record: null,
      },
      weekConsumeScooter: {
        scooterAvgs: [],
        stationAvgs: [],
        items: [],
      },
    };
  },
  mounted() {
    getMothRecord().then(res=>{
        let math = require('mathjs');  
      const datas = res.data.list;
      for (let i = datas.length - 1; i > -1; i--) {
        datas[i].changeStation = math.random() * 500000
        datas[i].activiteStation = math.random() * 302000
        this.weekConsumeScooter.scooterAvgs.push(datas[i].changeStation);
        this.weekConsumeScooter.stationAvgs.push(datas[i].activiteStation);
        this.weekConsumeScooter.items.push(datas[i].createTime);
      };
      this.setQiCheNumberDatasTwo();
    })
  },
  methods: {
    setQiCheNumberDatasTwo() {
      const swapFrequency = this.$t(`swapFrequency`);
      const swapStations = this.$t(`swapStations`);
      console.log(
        "this.weekConsumeScooter.活跃、里程",
        this.weekConsumeScooter
      );
      this.options.record = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15px",
          top: "36px",
          right: "15px",
          bottom: "2px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,//是否在边开始
            axisTick: { show: false },
            axisLabel: {
              //x轴  字体
              textStyle: {
                color: "#72747d", //刻度颜色
                fontSize: 12, //刻度大小
              },
            },
            axisLine: {
              //x轴设置
              show: true, //x轴是否显示
              lineStyle: {
                color: "#fff",
                width: 0.2,
                type: "solid",
              },
            },
            data: this.weekConsumeScooter.items,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "Stations",
            // min: 0,
            // max: 250,
            position: "left",
            nameTextStyle: { color: "#75829e" },
            offset: 0,
            axisLabel: {
              //formatter: '{value} %'
              show: true,
              textStyle: {
                color: "#72747d",
                fontSize: "12",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              //x轴设置
              show: false, //x轴是否显示
              lineStyle: {
                color: "#ffffff",
                width: 0.2,
                type: "solid",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,.1)",
              },
            },
          },
          {
            type: "value",
            // name: "km",
            nameTextStyle: { color: "#75829e" }, //控制name的温度
            position: "right",
            axisLabel: {
              formatter: "{value}",
              show: true,
              textStyle: {
                color: "#72747d",
                fontSize: "12",
              },
            },
            axisTick: {
              show: false,
            },

            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,.1	)",
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,.1)",
              },
            },
          },
        ],
        series: [
          {
            name: this.$t(`swapStations`),
            type: "line",
            data: this.weekConsumeScooter.stationAvgs,
            barWidth: "20%", //柱子宽度
            // barGap: 1, //柱子之间间距
            itemStyle: {
              normal: {
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            name: this.$t(`swapFrequency`),
            type: "line",
            itemStyle: {
              normal: {
                color: "#FB6C4A", //折线点颜色
                lineStyle: {
                  color: "#F28E27", //折线颜色
                },
              },
            },
            yAxisIndex: 1,
            data: this.weekConsumeScooter.scooterAvgs,
          },
        ],
        legend: {
          data: [swapStations, swapFrequency],
          selected: {
            swapStations: true,
            swapFrequency: true,
          },
          right: 60,
          textStyle: {
            color: "#c4d1e8",
          },
        },
      };
      this.$echarts
        .init(document.getElementById("recordMoth"))
        .setOption(this.options.record);
    },
  },
};
</script>