<template>
  <div id="content2-right" style="width: 100%; height: 80%"></div>
</template>
<script>
import {
  getMothOrder,//近6月月充值的订单/月订单总额
} from "../../../request/conlog.js";
export default {
  name: "seventScooter", //订单
  data() {
    return {
      options: {
        orders: null,
      },
      WeekConsume: {
        lineUpData: [],
        dataDateArr: [],
        activeData: [],
      },
    };
  },
  mounted() {
    getMothOrder().then(res=>{
      let math = require('mathjs'); 
      if(res.errcode === 200){
        const datas = res.data.list;
        for (let i = datas.length - 1; i > -1; i--) {
          datas[i].orderAmount = math.random() * 100150210
        datas[i].orderNum = math.random() * 302000
          this.WeekConsume.dataDateArr.push(String(datas[i].orderAmount));
          this.WeekConsume.lineUpData.push(datas[i].createTime);
          this.WeekConsume.activeData.push(datas[i].orderNum);
        }
        this.setScooter(); 
      }
    })
  },
  methods: {
    //2.0
    setScooter() {
      const orders = this.$t(`orders`)
      console.log("lineUpData", this.WeekConsume.dataDateArr);
      this.options.orders = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15px",
          top: "36px",
          right: "15px",
          bottom: "2px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,//是否在边开始
            axisTick: { show: false },
            axisLabel: {
              //x轴  字体
              textStyle: {
                color: "#72747d", //刻度颜色
                fontSize: 12, //刻度大小
              },
            },
            axisLine: {
              //x轴设置
              show: true, //x轴是否显示
              lineStyle: {
                color: "#fff",
                width: 0.2,
                type: "solid",
              },
            },
            data: this.WeekConsume.lineUpData,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: this.$t(`orders`),
            // min: 0,
            // max: 250,
            position: "left",
            nameTextStyle: { 
              color: "#75829e",
              align: "right",
              padding: [0, 5, 0, 0]
            },
            offset: 0,
            axisLabel: {
              //formatter: '{value} %'
              show: true,
              textStyle: {
                color: "#72747d",
                fontSize: "12",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              //x轴设置
              show: false, //x轴是否显示
              lineStyle: {
                color: "#ffffff",
                width: 0.2,
                type: "solid",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,.1)",
              },
            },
          },
          {
            type: "value",
            name: "",
            nameTextStyle: { color: "#75829e" }, //控制name的温度
            position: "right",
            axisLabel: {
              formatter: "{value}",
              show: true,
              textStyle: {
                color: "#72747d",
                fontSize: "12",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,.1	)",
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,.1)",
              },
            },
          },
        ],
        series: [
          {
            name: this.$t(`orders`),
            type: "bar",
            data: this.WeekConsume.activeData,
            barWidth: "20%", //柱子宽度
            // barGap: 1, //柱子之间间距
            itemStyle: {
              normal: {
                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#0DCCFF",
                  },
                  {
                    offset: 1,
                    color: "#4760FF",
                  },
                ]),
                //opacity: 1,
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            name: "Rp",
            type: "line",
            itemStyle: {
              normal: {
                color: "#FB6C4A", //折线点颜色
                lineStyle: {
                  color: "#F28E27", //折线颜色
                },
              },
            },
            yAxisIndex: 1,
            // data: [10, 23, 69, 63, 522, 10, 90],
            data: this.WeekConsume.dataDateArr,
          },
        ],
        legend: {
          data: [orders, "Rp"],
          selected: {
            orders: true,
            Rp: true,
          },
          // orient: 'vertical',
          right: 60,
          // top: 'center'
          // backgroundColor: '#ccc',
          textStyle: {
            color: "#c4d1e8",
          },
        },
      };
      this.$echarts
        .init(document.getElementById("content2-right"))
        .setOption(this.options.orders);
    },
  },
};
</script>