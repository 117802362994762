<template>
  <div id="content2-left" style="width: 100%; height: 86%"></div>
</template>
<script>
import { getHouersData } from "../../../request/conlog.js";
export default {
  name: "seventActive",
  data() {
    return {
      options: {
        mileage: null,
      },
      weekOrder: {
        lineUpData: [],
        dataDateArr: [],
      },
    };
  },

  mounted() {
    this.setHuanElement();
  },
  methods: {
    //千分数字处理
    Thousands(num) {
      //console.log('num',num)
      var result = [],
        counter = 0;
      num = (num || 0).toString().split("");
      for (var i = num.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(num[i]);
        if (!(counter % 3) && i != 0) {
          result.unshift(",");
        }
      }
      return result.join("");
    },
    //24小时换电高峰
    setHuanElement() {
      let that = this;
      let serdata = [];
      getHouersData().then((res) => {
        console.log("24小时换电频次", res);
        let math = require('mathjs');  
        if (res.errcode === 200) {
          const data = res.data.data;
          data.forEach((item, index) => {
            let md = that.Thousands(item.count);
            //console.log('md',md)
            serdata.push(item.count * math.random() * 3.5);
          });

          this.options.hours = {
            color: "rgba(0, 184, 236, 0.9)",
            tooltip: {
              trigger: "axis",
              axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
              },
              backgroundColor: "#fff", //背景色
              padding: [5, 15, 5, 15], //边距6
              borderColor: "#DDDDDF", //边框颜色
              borderWidth: 1, //边框线宽度
              textStyle: {
                //文字样式
                color: "#6A6A6A",
                decoration: "none",
                fontFamily: "Verdana, sans-serif",
              },
              extraCssText: "text-align: left;", //文字对齐方式
              formatter: function (params) {
                //格式化函数
                return (
                  params[0].name +
                  " hour</br>" +
                  that.Thousands(params[0].value)
                );
              },
            },
            legend: {
              show: true,
              orient: "left",
              x: "right",
              textStyle: {
                fontSize: "14",
                color: "#fff",
              },
            },
            grid: {
              left: "15px",
              top: "36px",
              right: "15px",
              bottom: "2px",
              containLabel: true,
            },
            textStyle: {
              fontSize: "14",
              fontWeight: "normal",
              color: "#ffffff",
            },
            xAxis: {
              name: "hours",
              type: "category",
              boundaryGap: false,
              axisTick: {
                //x轴边上尖尖刻度
                show: false,
                alignWithLabel: true,
                inside: true,
              },
              axisLabel: {
                color: "#72747d",
                // interval: 0,
                fontSize: 12,
                rotate: 45,
              },
              axisLine: {
                //onZero: true,

                show: true, //x轴是否显示
                lineStyle: {
                  color: "#ffffff",
                  width: 0.2,
                  type: "solid",
                },
              },
              axisPointer: {
                label: {
                  show: false,
                  backgroundColor: "#007EDD",
                },
              },
              data: [
                "00:00-01:00",
                "01:00-02:00",
                "02:00-03:00",
                "03:00-04:00",
                "04:00-05:00",
                "05:00-06:00",
                "06:00-07:00",
                "07:00-08:00",
                "08:00-09:00",
                "09:00-10:00",
                "10:00-11:00",
                "11:00-12:00",
                "12:00-13:00",
                "13:00-14:00",
                "14:00-15:00",
                "15:00-16:00",
                "16:00-17:00",
                "17:00-18:00",
                "18:00-19:00",
                "19:00-20:00",
                "20:00-21:00",
                "21:00-22:00",
                "22:00-23:00",
                "23:00-24:00",
              ],
            },
            yAxis: {
              name: this.$t(`times`),
              nameTextStyle: {
                color: "#75829e",
                align: "right",
                padding: [0, 10, 0, 0]
              },
              show: true,

              axisLabel: {
                color: "#72747d",
                interval: 0,
                fontSize: 12,
              },
              axisTick: {
                alignWithLabel: true,
                inside: true,
              },
              axisLine: {
                onZero: false,
                lineStyle: {
                  color: "#fff",

                  type: "solid",
                },
              },
              axisPointer: {
                label: {
                  show: false,
                },
              },
              type: "value",
              symbol: "circle",
              symbolSize: 10,
              smooth: true,
              splitLine: {
                show: false,

                lineStyle: {
                  // 属性lineStyle（详见lineStyle）控制线条样式
                  color: "#fff",
                  width: 0.4,
                  type: "solid",
                },
              },
            },
            series: [
              {
                type: "line",
                //name: '产品或服务产能指数',
                symbol: "circle",
                symbolSize: 10,
                smooth: true,

                itemStyle: {
                  //线上标点样式
                  normal: {
                    color: "rgba(0, 184, 236, 0)",
                  },
                  emphasis: {
                    color: "rgba(0, 184, 236, 0)",
                  },
                },
                lineStyle: {
                  //线条样式
                  normal: {
                    color: "#19bffc", //'rgba(0, 184, 236, 0.9)'
                  },
                },
                data: serdata,
                areaStyle: {
                  //线条与X轴面积样式
                  normal: {
                    color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#19bffc", // 0% 处的颜色
                      },
                      {
                        offset: 0.1,
                        color: "#4ED9FF", // 100% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#0f2049", // 100% 处的颜色
                      },
                    ]),
                  },
                },
              },
            ],
          };
          this.$echarts
            .init(document.getElementById("content2-left"))
            .setOption(this.options.hours);
        }
      });
    },
  },
};
</script>